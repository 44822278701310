
import { Button, Divider, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Machine, MachineConfig } from "../SubPages/DataCenterDetails";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SnoozeIcon from '@mui/icons-material/Snooze';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MachineUpdateHostingCharge from "./machine/MachineUpdateHostingCharge";
import MachineDelete from "./machine/MachineDelete";
import HandymanIcon from '@mui/icons-material/Handyman';
import MachineRepair from "./machine/MachineRepair";
import MachineEdit from "./MachineEdit";
import AddToUserGroup from "./AddToUserGroup";
import MachineUpdateHashRate from "./MachineUpdateHashRate";
import MachineSnooze from "./MachineSnooze";
import MachineEnableDisable from "./MachineEnableDisable";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import MachineRestart from "./machine/MachineRestart";
import RateReviewIcon from '@mui/icons-material/RateReview';
import MachineReview from "./machine/MachineReview";
import MachineUpdatePool from "./machine/MachineUpdatePool";
import MergeMachines from "./machine/MergeMachines";
import CallMergeIcon from '@mui/icons-material/CallMerge';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


function MachineSettings(prop: { data: Machine, refresh: () => void, machineConfigs: MachineConfig[] }) {


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [machineToDelete, setMachineToDelete] = useState<boolean | null>(null);
    const [machineToRepair, setMachineToRepair] = useState<boolean | null>(null);
    const [updateHostingCharge, setUpdateHostingCharge] = useState<boolean | null>(null);
    const [changePool, setChangePool] = useState<boolean | null>(null);
    const [mergeMachines,setMergeMachines] =  useState<boolean | null>(null);
    const [editMachine, setEditMachine] = useState<boolean | null>(null);
    const [addToUserGroup, setAddToUserGroup] = useState<boolean | null>(null);
    const [machineUpdateHashRate, setMachineUpdateHashRate] = useState<boolean | null>(null);
    const [machineToSnooze, setMachineToSnooze] = useState<boolean | null>(null);
    const [machineToDisableEnable, setMachineToDisableEnable] = useState<boolean | null>(null);
    const [machineRestart, setMachineRestart] = useState<boolean | null>(null);
    const [machineToReview, setMachineToReview] = useState<boolean | null>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function copyData(d: Machine) {
        navigator.clipboard.writeText(JSON.stringify(d))
    }

    useEffect(() => {
    }, [])

    return (<>

        <Button
            id="settings-button"
            aria-controls={open ? 'settings-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
        >
            <SettingsIcon />
        </Button>
        <StyledMenu
            id="settings-menu"
            MenuListProps={{
                'aria-labelledby': 'settings-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={() => { handleClose(); copyData(prop.data) }} disableRipple>
                <ContentCopyIcon />
                Copy
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setEditMachine(true) }} disableRipple>
                <EditIcon />
                Edit
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setChangePool(true) }} disableRipple>
                <EditIcon />
                Change Pool
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setMergeMachines(true) }} disableRipple>
                <CallMergeIcon />
                Merge Machines
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setMachineUpdateHashRate(true) }} disableRipple>
                <RoomPreferencesIcon />
                Hash Rate
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setAddToUserGroup(true) }} disableRipple>
                <PersonAddAltIcon />
                Add to user Group
            </MenuItem>
            <MenuItem onClick={() => { handleClose(); setUpdateHostingCharge(true) }} disableRipple>
                <MonetizationOnIcon />
                Update Hosting charge
            </MenuItem>
            {(!prop.data.disable) && (prop.data.isSnoozed) && (<MenuItem onClick={() => { handleClose(); setMachineToSnooze(true) }} disableRipple>
                <AlarmOnIcon />
                Wake up
            </MenuItem>)}
            {(!prop.data.disable) && (!prop.data.isSnoozed) && (<MenuItem onClick={() => { handleClose(); setMachineToSnooze(true) }} disableRipple>
                <SnoozeIcon />
                Snooze
            </MenuItem>)}
            {(prop.data.disable) && (<MenuItem onClick={() => { handleClose(); setMachineToDisableEnable(true) }} disableRipple>
                <CheckCircleOutlineIcon />
                Enable
            </MenuItem>)}
            {(!prop.data.disable) && (<MenuItem onClick={() => { handleClose(); setMachineToDisableEnable(true) }} disableRipple>
                <BlockIcon />
                Disable
            </MenuItem>)}
            <Divider sx={{ my: 0.5 }} />
            {(!prop.data.isSnoozed) && (!prop.data.disable) && (!(prop.data.repairing ?? false)) && (
                <MenuItem onClick={() => { handleClose(); setMachineToRepair(true) }} disableRipple>
                    <HandymanIcon />
                    Sent to Repair
                </MenuItem>)}
            {(prop.data.repairing ?? false) && (<MenuItem onClick={() => { handleClose(); setMachineToRepair(true) }} disableRipple>
                <HandymanIcon />
                Repair completed
            </MenuItem>)}
            {(!prop.data.disable) && (<MenuItem onClick={() => { handleClose(); setMachineToReview(true) }} disableRipple>
                <RateReviewIcon />
                Review
            </MenuItem>)}

            {(!prop.data.disable) && (<MenuItem onClick={() => { handleClose(); setMachineRestart(true) }} disableRipple>
                <RestartAltIcon />
                Restart
            </MenuItem>)}
            {/* <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => { handleClose(); setMachineToDelete(prop.data); }} disableRipple>
                <DeleteIcon />
                Delete
            </MenuItem> */}
        </StyledMenu>
        {(changePool != null) && (<MachineUpdatePool data={prop.data} onClose={()=>{setChangePool(null)}} afterUpdate={() => { setChangePool(null); prop.refresh() }} />)}
        {(mergeMachines != null) && (<MergeMachines data={prop.data} onClose={()=>{setMergeMachines(null)}} afterUpdate={() => { setMergeMachines(null); prop.refresh() }} />)}
        {(updateHostingCharge != null) && (<MachineUpdateHostingCharge macIds={[prop.data.macId]} value={prop.data.hostingCost} onClose={() => { setUpdateHostingCharge(null) }} afterUpdate={() => { setUpdateHostingCharge(null); prop.refresh() }} />)}
        {(machineToDelete != null) && (<MachineDelete data={prop.data} onClose={() => { setMachineToDelete(null) }} afterDelete={() => { setMachineToDelete(null); prop.refresh(); }} />)}
        {(machineToRepair != null) && <MachineRepair onChange={() => { setMachineToRepair(null); prop.refresh() }} onCancel={() => { setMachineToRepair(null) }} data={prop.data} />}
        {(editMachine != null) && (<MachineEdit data={prop.data} machineConfigs={prop.machineConfigs} processCancelled={() => { setEditMachine(null); }} updated={(d: any) => { setEditMachine(null); prop.refresh(); }} />)}
        {(addToUserGroup != null) && (<AddToUserGroup data={prop.data} onClose={() => { setAddToUserGroup(null) }} onSave={() => { setAddToUserGroup(null); prop.refresh(); }} />)}
        {(machineUpdateHashRate != null) && (<MachineUpdateHashRate onChange={() => { setMachineUpdateHashRate(null); prop.refresh(); }} onCancel={() => { setMachineUpdateHashRate(null) }} data={prop.data} />)}
        {(machineToSnooze != null) && (<MachineSnooze onChange={(data: Machine) => { setMachineToSnooze(null); prop.refresh(); }} onCancel={() => { setMachineToSnooze(null) }} data={prop.data} />)}
        {(machineToDisableEnable != null) && (<MachineEnableDisable onChange={() => { setMachineToDisableEnable(null); prop.refresh(); }} onCancel={() => { setMachineToDisableEnable(null) }} data={prop.data} />)}
        {(machineRestart != null) && (<MachineRestart onChange={() => { setMachineRestart(null); prop.refresh(); }} onCancel={() => { setMachineRestart(null) }} data={prop.data} />)}
        {(machineToReview != null) && (<MachineReview onChange={() => { setMachineToReview(null); prop.refresh(); }} onCancel={() => { setMachineToReview(null) }} data={prop.data} />)}
    </>)
}

export default MachineSettings;
