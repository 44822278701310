import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { API_PATH } from "../../../../App";
import { UserGroup } from "./SettingsUserGroup";
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';


function SettingsUserGroupDelete(props: { afterDelete: () => void, onClose: () => void, data: UserGroup }) {
    const { afterDelete, onClose, data } = props
    const [isProcessingData, setIsProcessingData] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    function deleteUG() {
        setIsProcessingData(true);
        setErrorMessage("")
        axios.delete(API_PATH + '/usergroup', { data: JSON.parse(JSON.stringify(data)) })
            .then(function (response) {
                setIsProcessingData(false)
                afterDelete();
            })
            .catch(function (error) {
                console.log(error);
                setIsProcessingData(false)
                setErrorMessage("Error deleting User Group")
            });
    }
    return <Dialog
        open={true}
        fullWidth={true}
        maxWidth="sm"
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Delete [#{data.id}] {data.name}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Delete user group - {data.name}
                {(errorMessage.trim().length > 0) && (<Alert severity="error">{errorMessage}</Alert>)}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isProcessingData}>Cancel</Button>
            <Button onClick={deleteUG} disabled={isProcessingData} color="error" > Delete </Button>
        </DialogActions>
    </Dialog>
}
export default SettingsUserGroupDelete;