import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import Button from '@mui/material/Button';

import dayjs from 'dayjs';
import Switch, { SwitchProps } from '@mui/material/Switch';

import { Machine } from "../../../SubPages/DataCenterDetails";
import ReactDOM from "react-dom";

import axios from "axios";
import { API_PATH } from "../../../../App";
import LoadingScreen from "../../LoadingScreen";

export interface MachineBilling {
    "id": number | null | undefined,
    "machine": Machine,
    "date": string,
    "hour": number,
    "up": boolean
}

export interface MachineBillingInfo {
    "machine": Machine,
    "info": Map<number, MachineBilling>
}

export function createBIllingObject(n: any): MachineBilling {
    return { id: n?.id, machine: n?.machine, date: n.date, hour: n.hour, up: n.up };
}


export function createBIllingEmptyObject(machine: Machine, date: string, hour: number): MachineBilling {
    return { id: null, machine: machine, date: date, hour: hour, up: false };
}

var tableTitles = [
    "12 AM - 1 AM",
    "1 AM - 2 AM",
    "2 AM - 3 AM",
    "3 AM - 4 AM",
    "4 AM - 5 AM",
    "5 AM - 6 AM",
    "6 AM -7 AM ",
    "7 AM - 8 AM",
    "8 AM - 9 AM",
    "9 AM - 10 AM",
    "10 AM - 11 AM",
    "11 AM - 12 PM",
    "12 PM - 1 PM ",
    "1 PM - 2 PM ",
    "2 PM - 3 PM",
    "3 PM - 4 PM",
    "4 PM - 5 PM",
    "5 PM - 6 PM",
    "6 PM - 7 PM",
    "7 PM - 8 PM",
    "8 PM - 9 PM",
    "9 PM -10 PM",
    "10 PM -11 PM",
    "11 PM - 12 AM"
]

export function emptyMachineBillingRow(machine: Machine, date: string): Map<number, MachineBilling> {
    var row = new Map<number, MachineBilling>()
    for (var i = 0; i < 24; i++) {
        row.set(i, createBIllingEmptyObject(machine, date, i))
    }
    return row;
}
function MAchineOnlineTable(props: { reSubmit: () => void, dataReceived: Map<string, MachineBillingInfo>, dateSelected: dayjs.Dayjs | null }) {

    const { dataReceived, dateSelected, reSubmit } = props

    const table = useRef(null);

    const [dataToUpdate, setDataToUpdate] = useState<Map<string, MachineBillingInfo>>(new Map());

    const [updateUnderProcessing, setUpdateUnderProcessing] = useState(false);

    function propUpdate(macId: string, hours: number, status: boolean) {
        var obj: MachineBilling = dataReceived.get(macId)!.info.get(hours)!;
        obj.up = status;
        dataReceived.get(macId)?.info.set(hours, obj);
        //setDataToUpdate(new Map(dataReceived));
        // onProcessing(true)
        // doUpdate(dataReceived.get(macId)!, (x) => {
        //     onProcessing(false)
        // })
    }
    function doUpdate(obj: MachineBillingInfo, success: (status: boolean) => void) {
        var statusMap: Map<string, boolean> = new Map()
        Array.from(obj.info.entries()).map((entry) => {
            const [key, row] = entry;
            statusMap.set(row.hour.toString(), row.up);
        });
        var request = {
            machineIds: [obj.machine.macId],
            date: dateSelected?.format("YYYY-MM-DD"),
            status: Object.fromEntries(statusMap)
        }
        axios.post(API_PATH + '/uptime/machine/update', JSON.parse(JSON.stringify(request)))
            .then((response) => {
                success(true)
            })
            .catch((error) => {
                console.log(error)
                success(false)
            })
    }
    function propBulkUpdate(hours: number, status: boolean) {
        var totalRequestPending = dataReceived.size;
        // onProcessing(true)
        Array.from(dataReceived.entries()).map((entry) => {
            const [key, row] = entry;
            var tmp = row.info.get(hours)!
            tmp.up = status;
            row.info.set(hours, tmp)
            dataReceived.set(key, row)
            dataToUpdate.set(key, row)
            var node = document.getElementById("billingInfo-" + tmp.machine.macId + "-" + tmp.hour);
            var switchNode = ReactDOM.findDOMNode(node);
            if ((switchNode != null) && (switchNode instanceof Element)) {
                var ip = switchNode.querySelector('input');
                if (ip != null) {
                    ip.checked = status
                }
            }
            // doUpdate(row, (x) => {
            //     totalRequestPending--;
            //     if (totalRequestPending >= 0) {
            //         onProcessing(false)
            //     }
            // })
        });
    }
    function updateBilling(e: any) {
        e.preventDefault();
    }

    function updateData() {
        var totalRequestPending = 0;
        setUpdateUnderProcessing(true)
        Array.from(dataReceived.entries()).map((entry) => {
            const [key, row] = entry;
            totalRequestPending++;
            doUpdate(row, (x) => {
                totalRequestPending--;
                if (totalRequestPending >= 0) {
                    setUpdateUnderProcessing(false)
                    reSubmit();
                }
            })
        });
    }
    useEffect(() => {
        // setDataToUpdate(dataReceived)
    }, [])

    return (<Grid container component={Paper} style={{ marginTop: "10px" }}  >
        <Grid item xs={12} style={{ overflow: "scroll" }} >
            <form onSubmit={updateBilling} >
                <div style={{ height: "500px", textAlign: "center" }}>
                    <table style={{ borderCollapse: "collapse" }} className="fixedTable" ref={table}>
                        <thead style={{ overflow: "auto", height: "100px" }}>
                            <tr>
                                <th key={"a"} className="fixedHeadCol fixedHeadRow" style={{ minWidth: "200px" }} >Machine</th>
                                {tableTitles.map((n, i) => <th key={i} className={"machineBillingTable fixedHeadRow"} >
                                    {n.split("-").map((k, j) => <span key={j}>{k}</span>)}
                                    <Switch defaultChecked={false} size="small" color="warning" onChange={e => { propBulkUpdate(i, e.target.checked) }} />
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(dataReceived.entries()).map((entry) => {
                                const [key, row] = entry;
                                return (
                                    <tr
                                        key={key}

                                    >
                                        <td style={{ width: "200px", maxWidth: "200px", wordBreak: "break-word" }} className="fixedHeadCol" >{row.machine.workerId}</td>
                                        {Array.from(row.info.entries()).map((timeEntry) => {
                                            const [timeKey, timeRow] = timeEntry;
                                            return <td key={timeKey} id={"billingInfo-" + timeRow.machine.macId + "-" + timeRow.hour}  > <CustomSwitch name={'billingInfo'} defaultChecked={timeRow.up} value={JSON.stringify(timeRow)} onChange={e => { propUpdate(key, timeKey, e) }} /> </td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </form>
        </Grid>
        <Grid item xs={12} style={{ padding: "10px", textAlign: "right" }}>
            <Button variant="contained" color="warning" size="small" onClick={updateData}> Update </Button>
        </Grid>
        {updateUnderProcessing && (<LoadingScreen />)}
    </Grid>)
}

function CustomSwitch(props: { value: string, name: string, defaultChecked: boolean, onChange: (state: boolean) => void }): JSX.Element {
    const { value, name, defaultChecked, onChange } = props
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (defaultChecked) {
            setIsChecked(defaultChecked)
        }
    }, [])
    return <label className="customSwitch">
        <input type="checkbox" className="checkbox" value={value} checked={isChecked} name={name} onChange={(e) => { setIsChecked(e.target.checked); onChange(e.target.checked); }} />
        <span className="customSlider"></span>
    </label>
}

export default MAchineOnlineTable;