import { useState } from "react";
import { Machine, timeElapsedSince } from "../../SubPages/DataCenterDetails";
import { Chip, Button } from "@mui/material";
import MachineHistory from "../MachineHistory";
import MachineInfoAditionalStatusInfo from "./MachineInfoAditionalStatusInfo";
import { getMachineSortTime } from "../../../Helpers/MachineHelper";


function MachineInfoWithHealth(props: { data: Machine, showHistory: boolean }) {
    const [machineHistory, setMachineHistory] = useState<Machine | null>(null);
    const { data } = props
    return <>
        <b className="truncateText2 " style={{ cursor: "pointer" }} onClick={() => { setMachineHistory(data) }} > {(data.workerId?.trim().length == 0) ? "[ WORKER ID NOT SET ]" : data.workerId} </b><br />
        {data.disable ? (<><span style={{ fontSize: "11px" }}>Disabled</span><br /></>) :
            (data.repairing) ? (<><span style={{ fontSize: "11px" }}>Repairing</span><br /></>) :
                (data.health?.hasError) ? (<>
                    <span style={{ fontSize: "11px" }}>{data.health?.errorDetails ?? data.health?.message} since - {(timeElapsedSince(getMachineSortTime(data)))} </span><br />
                </>) :
                    (data.health?.hasWarning && (data.health?.message != null)) ? (<><span style={{ fontSize: "11px" }}>{data.health.message}</span><br /></>) :
                        (<></>)}
        <MachineInfoAditionalStatusInfo data={data} />
        <>
            {(data.health?.hashRate != null) && (<Chip className="tinyTxt" label={"H : " + data.health?.hashRate} size="small" />)}
            {(data.health?.fanSpeed != null) && (<Chip className="tinyTxt" label={"F : " + data.health?.fanSpeed} size="small" />)}
            {(data.health?.temperature1 != null) && (<Chip className="tinyTxt" label={"T : " + data.health?.temperature1} size="small" />)}
            {(data.virt == true) && (<Chip  style={{background:"#ffea00"}} className="tinyTxt" label={"virt"} size="small" />)}
        </>
        {data.f2Pool != null && (<Button style={{ padding: "1px", fontSize: "10px" }} variant="contained" href={data.f2Pool.url} size="small" target="blank">{'POOL'}</Button>)}
        {(machineHistory != null && props.showHistory) && (<MachineHistory onClose={() => { setMachineHistory(null) }} data={machineHistory} />)}
        {/* <> <br /> <span> {data.errorStatus?.errorShortMessage}  {timeElapsedSince(getMachineSortTime(data))}</span> </> */}
    </>
}
export default MachineInfoWithHealth;