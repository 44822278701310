import { Grid, Paper, Typography, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Button, Alert } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { UserGroup } from "../UserGroup/SettingsUserGroup";
import axios from "axios";
import { API_PATH } from "../../../../App";
import LoadingScreen from "../../LoadingScreen";
import MachineBillingResult from "./MachineBillingResult";
import { ModelConfig } from "../../../SubPages/DataCenterDetails";

export interface BillingShortDetail {
    units: string,
    hostingCost: string,
    hours: string,
    totalCharge: string,
    internalModel: string,
    machineConfigId: string,
    power: string
}

export interface BillingExpandedDetail {
    id: string,
    workerid: string
    hours: string
}

function MachineBillingInfo() {
    const [isProcessingData, setIsProcessingData] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
    const [dataReceived, setDataReceived] = useState<BillingShortDetail[] | null>(null);
    const [dataError, setDataError] = useState<string | null>(null);
    const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroup | null>(null)
    const [userGroups, setUserGroups] = useState<UserGroup[]>([])

    function getInfo() {
        if (startDate == null) {
            return;
        }
        if (endDate == null) {
            return;
        }
        if (selectedUserGroups == null) {
            return;
        }
        setIsProcessingData(true);
        setDataReceived(null)
        setDataError(null)
        axios.get('/' + API_PATH + '/billing?usergroup=' + selectedUserGroups.id + '&from=' + startDate?.format("YYYY-MM-DD") + '&to=' + endDate?.format("YYYY-MM-DD"))
            .then(function (response) {
                var res: BillingShortDetail[] = []
                response.data.forEach((n: any) => {
                    res.push(n)
                });
                setDataReceived(res);
                setIsProcessingData(false)
            })
            .catch(function (error) {
                console.log(error);
                setDataError(error.response.data);
                setDataReceived(null)
                setIsProcessingData(false)
            })
    }

    function getAllUserGroup() {
        setIsProcessingData(true);

        axios.get('/' + API_PATH + '/usergroup')
            .then(function (response) {
                var res: UserGroup[] = []
                response.data.forEach((n: any) => {
                    res[n.id] = n
                });
                setUserGroups(res);
                setIsProcessingData(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsProcessingData(false)
            })
    }

    useEffect(() => {
        getAllUserGroup()
    }, [])

    return <Grid container style={{ padding: "10px" }}   >
        <Paper style={{ width: "100%", padding: "10px" }}>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography variant="h6" component="h2"> Machine Billing Info </Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: "10px" }} spacing={1} >
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => { setStartDate(newValue); setDataReceived(null); }}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => { setEndDate(newValue); setDataReceived(null); }}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <FormControl fullWidth>
                            <InputLabel id="ug-label">User Group</InputLabel>
                            <Select
                                labelId="ug-label"
                                value={selectedUserGroups?.id ?? ""}
                                label="User Group"
                                onChange={(e: SelectChangeEvent) => { setSelectedUserGroups(userGroups[Number(e.target.value)]); setDataReceived(null); }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {userGroups.map(n => <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}  >
                        <FormControl fullWidth >
                            <Button variant="contained" size="large" onClick={getInfo} style={{ padding: "14px 5px" }} > Generate </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
        {(dataReceived != null) && <MachineBillingResult data={dataReceived} usergroup={selectedUserGroups!} from={startDate!} to={endDate!} />}
        {(isProcessingData) && <LoadingScreen />}
        {(dataError != null) && (<Grid container style={{ paddingTop: "10px" }}   ><Alert severity="error">Error fetching data on : {dataError}</Alert></Grid>)}
    </Grid >
}
export default MachineBillingInfo;