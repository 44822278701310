import { Button, FormControl, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react";
import MachineBillingResultRow from "./MachineBillingResultRow";
import { BillingShortDetail } from "./MachineBillingInfo";
import dayjs, { Dayjs } from "dayjs";
import { UserGroup } from "../UserGroup/SettingsUserGroup";
import { formatNumber } from "../../../../Helpers/HelperFunctions";
import axios from "axios";
import { API_PATH } from "../../../../App";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function MachineBillingResult(props: { data: BillingShortDetail[], usergroup?: UserGroup, from?: Dayjs, to?: Dayjs, uniqId?: string }) {
    const [generatedUrl, setGeneratedUrl] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    var total = 0.0;
    function generatePublicUrl() {
        setIsLoading(true)
        axios.get('/' + API_PATH + '/billing/generateUrl?usergroup=' + props.usergroup?.id + '&from=' + props.from?.format("YYYY-MM-DD") + '&to=' + props.to?.format("YYYY-MM-DD"))
            .then(function (response) {
                if (response.data.uniqueId) {
                    setGeneratedUrl(window.location.protocol + "//" + window.location.host + "/sharedBilling?id=" + response.data.uniqueId);
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setGeneratedUrl('')
                setIsLoading(false)
            })
    }
    function copyUrl() {
        navigator.clipboard.writeText(generatedUrl)
    }
    return <Grid container style={{ paddingTop: "10px" }}   >
        {!props.uniqId && (
            <Grid container>
                <Grid container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    component={Paper}
                    style={{ marginBottom: "10px", padding: "5px" }}>
                    {(generatedUrl.length == 0) && (
                        <Grid item xs={12} sm={3}  >
                            <FormControl fullWidth >
                                <Button variant="contained" size="large" onClick={generatePublicUrl} disabled={isLoading} > Generate Public Url </Button>
                            </FormControl>
                        </Grid>
                    )}
                    {(generatedUrl.length > 0) && (
                        <Grid item xs={12} >
                            <span>{generatedUrl}</span>
                            <a style={{ marginLeft: "30px", cursor: "pointer" }} onClick={copyUrl} ><ContentCopyIcon /></a>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        )}
        <Grid item xs={12}>
            <TableContainer component={Paper} >
                <Table size="small" aria-label="Billing">
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Hosting Charge</TableCell>
                            <TableCell align="right">Power</TableCell>
                            <TableCell align="right">Total hours</TableCell>
                            <TableCell align="right">Charge</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row, i) => {
                            total += Number(formatNumber(row.totalCharge));
                            return <MachineBillingResultRow data={row} key={row.internalModel+i} usergroup={props.usergroup} from={props.from} to={props.to} uniqId={props.uniqId} />
                        })}

                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell > &nbsp;</TableCell>
                            <TableCell > &nbsp;</TableCell>
                            <TableCell > &nbsp;</TableCell>
                            <TableCell > &nbsp;</TableCell>
                            <TableCell align="right"> <b>Total</b></TableCell>
                            <TableCell align="right"><b>{formatNumber(total)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </Grid>
}

export default MachineBillingResult