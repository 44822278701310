import { Badge, IconButton, Typography } from "@mui/material";

import NotificationsIcon from '@mui/icons-material/Notifications';
import { appContext } from "../../App";
import { useContext } from "react";


function TopToolbar() {
  const page = useContext(appContext);

  return (<>
    <Typography
      component="h1"
      variant="h6"
      color="inherit"
      noWrap
      sx={{ flexGrow: 1 }}
    >
      {page}
    </Typography>
    {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
  </>)
}

export default TopToolbar;