import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { BillingExpandedDetail, BillingShortDetail } from "./MachineBillingInfo";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { API_PATH } from "../../../../App";
import { UserGroup } from "../UserGroup/SettingsUserGroup";
import { formatNumber } from "../../../../Helpers/HelperFunctions";


function MachineBillingResultRow(props: { data: BillingShortDetail, usergroup?: UserGroup, from?: Dayjs, to?: Dayjs, uniqId?: string }) {

    const [showDetailed, setShowDetailed] = useState<boolean>(false);
    const [dataReceived, setDataReceived] = useState<BillingExpandedDetail[] | null>(null);

    function getDetailedInfo() {
        setShowDetailed(!Boolean(showDetailed))
        setDataReceived(null)
        if (showDetailed) {
            return;
        }
        var urlString: string = ""
        if (props.uniqId) {
            urlString = API_PATH + '/public/billing/expand?id=' + props.uniqId +
                '&rate=' + props.data.hostingCost +
                '&machineConfig=' + props.data.machineConfigId
        } else {
            urlString = API_PATH + '/billing/expand?usergroup=' + props.usergroup?.id +
                '&from=' + props.from?.format("YYYY-MM-DD") +
                '&to=' + props.to?.format("YYYY-MM-DD") +
                '&rate=' + props.data.hostingCost +
                '&machineConfig=' + props.data.machineConfigId
        }
        axios.get(urlString)
            .then(function (response) {
                var res: BillingExpandedDetail[] = []
                response.data.forEach((n: any) => {

                    res.push(n)
                });
                setDataReceived(res);
            }).catch(e => {
                setDataReceived(null);
            })
    }

    return <>
        <TableRow

            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                {props.data.internalModel}
                <a style={{ float: "right", cursor: "pointer" }} onClick={getDetailedInfo} > {(showDetailed) && (<KeyboardArrowUpIcon />)} {(!showDetailed) && (<KeyboardArrowDownIcon />)} </a>
            </TableCell>
            <TableCell align="right">{props.data.units}</TableCell>
            <TableCell align="right">{props.data.hostingCost}</TableCell>
            <TableCell align="right">{props.data.power}</TableCell>
            <TableCell align="right">{props.data.hours}</TableCell>
            <TableCell align="right">{formatNumber(props.data.totalCharge)}</TableCell>
        </TableRow>
        {(showDetailed) && (<>
            {(dataReceived == null) && (<TableRow><TableCell colSpan={6} style={{ textAlign: "center" }}><CircularProgress size={20} /></TableCell></TableRow>)}
            {(dataReceived != null) && (
                <TableRow>
                    <TableCell colSpan={5} align="center">
                        <TableContainer component={Paper} >
                            <Table size="small" aria-label="Billing">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b><small>Machine</small></b></TableCell>
                                        <TableCell align="right"><b><small>Hours</small></b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataReceived.map(x => {
                                            return <TableRow>
                                                <TableCell ><small>{x.workerid}</small></TableCell>
                                                <TableCell align="right"><small>{x.hours}</small></TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableCell>
                    <TableCell align="center">&nbsp;</TableCell>
                </TableRow>
            )}
        </>)}
    </>
}

export default MachineBillingResultRow