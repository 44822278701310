import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from "axios";
import { API_PATH } from "../../../../App";
import { UserGroup } from "./SettingsUserGroup";
import { F2Pool, createF2Pool } from "../Pool/SettingsPool";

function SettingsUserGroupAdd(props: { onSave: () => void, onClose: () => void, data: UserGroup | null }) {
    const { onSave, onClose, data } = props
    const [isProcessingData, setIsProcessingData] = useState<boolean>(false);
    const [isCheckPassed, setIsCheckPassed] = useState<boolean | null>(null);
    const [userGroup, setUserGroup] = useState(data?.name ?? "");
    const [errorMessage, setErrorMessage] = useState("");
    const [f2PoolOptions, setF2PoolOptions] = useState<{ label: string, id: number, f2Pool: F2Pool }[]>([]);

    const [f2poolSelected, setF2poolSelected] = React.useState<{ label: string, id: number }[]>([]);

    function loadF2Pools() {
        setIsProcessingData(true);
        axios.get('/' + API_PATH + '/setting/f2pool')
            .then(function (response) {
                var res: { label: string, id: number, f2Pool: F2Pool }[] = []
                response.data.forEach((n: any) => {
                    res[n.id] = { label: n.url, id: n.id, f2Pool: createF2Pool(n) }
                });
                setF2PoolOptions(res);
                setIsProcessingData(false)
            })
            .catch(function (error) {
                setIsProcessingData(false)
                console.log(error);
            })
    }
    function saveData() {
        if (userGroup.trim().length == 0) {
            setIsCheckPassed(false)
            setErrorMessage("User Group can't be empty...")
            return;
        }
        setIsCheckPassed(true);
        setIsProcessingData(true);
        var selectedF2PoolsObjects: any[] = []
        f2poolSelected.map(n => {
            selectedF2PoolsObjects.push(JSON.parse(JSON.stringify(f2PoolOptions[n.id].f2Pool)))
        })
        if (data == null) {
            axios.post('/' + API_PATH + '/usergroup',
                { "name": userGroup, "f2Pools": selectedF2PoolsObjects })
                .then(function (response) {
                    setIsProcessingData(false)
                    if (response.data.id) {
                        onSave()
                    } else {
                        setErrorMessage(response.data.error)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            axios.put('/' + API_PATH + '/usergroup',
                { id: data.id, "name": userGroup, "f2Pools": selectedF2PoolsObjects })
                .then(function (response) {
                    setIsProcessingData(false)
                    if (response.data.id) {
                        onSave()
                    } else {
                        setErrorMessage(response.data.error)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    useEffect(() => {
        loadF2Pools()
        if (data) {
            var defaultPoolSelection: { label: string, id: number }[] = []
            data.f2Pools.map(n => { defaultPoolSelection.push({ label: n.url, id: Number(n.id) }) })
            setF2poolSelected(defaultPoolSelection)
        }
        return () => { }
    }, [])
    return <Dialog
        open={true}
        PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                saveData()
            },
        }}
        fullWidth={true}
        maxWidth="md" >
        <DialogTitle>{(data == null) ? "Add" : "Update"} User Group {(data == null) ? "" : " => [#" + data.id + "] " + data.name}</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="pool-url">User Group</InputLabel>
                        <OutlinedInput
                            id="pool-url"
                            type={'text'}
                            error={!(isCheckPassed ?? true)}
                            label="User Group"
                            fullWidth
                            value={userGroup}
                            onChange={e => { setUserGroup(e.target.value); setIsCheckPassed(null) }}
                            onKeyUp={e => { if (e.key === 'Enter' || e.keyCode === 13) { saveData() } }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px", minHeight: "300px" }}>
                <Autocomplete
                    disablePortal
                    fullWidth
                    multiple
                    options={f2PoolOptions}
                    value={(f2PoolOptions.length == 0) ? [] : f2poolSelected}
                    onChange={(event: any, newValue: { label: string, id: number }[]) => {
                        setF2poolSelected(newValue)
                    }}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} label="F2Pool" />}
                />
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isProcessingData}>Cancel</Button>
            <Button type="submit" disabled={isProcessingData}> {(data == null) ? "Add" : "Update"}</Button>
        </DialogActions>
        {(errorMessage != "") && (<Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => { setErrorMessage("") }}
            message={errorMessage}
        />)}
    </Dialog>
}
export default SettingsUserGroupAdd;