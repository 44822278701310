import React, { useEffect, useState } from "react";
import { Machine } from "../SubPages/DataCenterDetails";
import { Button, TextField, InputAdornment, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../App";
import MenuItem from '@mui/material/MenuItem';

function MachineSnooze(props: { onChange: (data: Machine) => void, onCancel: () => void, data: Machine }) {
  const { onChange, onCancel, data } = props
  const [snoozedTill, setSnoozedTill] = React.useState<string>("");
  const [snoozedSteps, setSnoozedSteps] = React.useState<number>(1);
  const [snoozedReason, setSnoozedReason] = React.useState("");
  const [alertElm, setAlertElm] = React.useState<any | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  function updteMachine() {
    if (!data.isSnoozed && (snoozedTill == "")) {
      setAlertElm(<Alert severity="error">Snooze till has an invalid value </Alert>)
      return
    }
    if (snoozedReason.trim() == "") {
      setAlertElm(<Alert severity="error">Message can't be empty</Alert>)
      return
    }

    setAlertElm(null)
    var x = data;
    // x.snoozeTill = data.snoozeTill
    x.snoozeInMinutes = snoozedSteps * parseInt(snoozedTill);
    x.message = snoozedReason + " [" + snoozedTill + " " + ((snoozedSteps == 1) ? "Minutes" : (snoozedSteps == 60) ? "Hours" : "Days") + "]";
    if (data.isSnoozed) {
      x.snoozeTill = 0
      x.snoozeInMinutes = 0
    }
    setIsProcessing(true)
    axios.post('/' + API_PATH + "/machine", x)
      .then(function (response) {
        setIsProcessing(false)
        onChange(x)
      })
      .catch(function (error) {
        setIsProcessing(false)
        console.log(error);
      });
  }

  useEffect(() => {

  }, [])
  return (<Dialog open={true} onClose={() => { onCancel() }} fullWidth maxWidth="sm" >
    {(!data.isSnoozed) && (<>
      <DialogTitle style={{ textAlign: "center" }}> Confirm Snooze  Machine <br /> #{data.id} {data.workerId}</DialogTitle>
      <DialogContent>
        <Grid container >
          <Grid xs={7}>
            <TextField
              autoFocus
              margin="dense"
              label=""
              fullWidth
              defaultValue={snoozedTill}
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start">Snooze  for </InputAdornment>
              }}
              onChange={(e) => { setSnoozedTill(e.target.value) }}
            />
          </Grid>
          <Grid xs={5}>
            <TextField
              autoFocus
              margin="dense"
              label=""
              fullWidth
              defaultValue={snoozedSteps}
              select
              variant="standard"
              onChange={(e) => { setSnoozedSteps(Number(e.target.value)) }}
            >
              <MenuItem value="1">Minutes</MenuItem>
              <MenuItem value="60">Hours</MenuItem>
              <MenuItem value="1440">Days</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          defaultValue={snoozedReason}
          style={{ marginTop: "5px" }}
          onChange={(e) => { setSnoozedReason(e.target.value) }}
        />
      </DialogContent>
    </>)}
    {(data.isSnoozed) && (<>
      <DialogTitle style={{ textAlign: "center" }}> Confirm Wake up  Machine <br /> #{data.id} {data.workerId}</DialogTitle>
      <DialogContent>
        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          defaultValue={snoozedReason}
          style={{ marginTop: "5px" }}
          onChange={(e) => { setSnoozedReason(e.target.value) }}
        />
      </DialogContent>
    </>)}
    <DialogActions>
      <Grid container>
        <Grid item xs={12}>
          {(alertElm != null) && (alertElm)}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={isProcessing} onClick={() => { onCancel() }}>Cancel</Button>
          <Button disabled={isProcessing} onClick={() => { updteMachine(); }} color="secondary">{(data.isSnoozed) ? "Wake up " : "Snooze"} </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>);
}

export default MachineSnooze;