import { Box, Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { appContext } from "../../../App";
import { useContext, useState } from "react";

import PaymentsIcon from '@mui/icons-material/Payments';
import { useNavigate } from "react-router-dom";


function TileMachineBillingStatus() {
  const page = useContext(appContext);
  const navigate = useNavigate();
  const [loadUserGroup, setUserGroup] = useState<boolean>(false);
  return (<Grid item xs={12} sm={6} md={4} lg={3}>
    <Card variant="outlined" style={{ margin: "5px" }}  >
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom variant="h5" component="div">
            Machine Billing
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            <PaymentsIcon />
          </Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          View Machine Billing Info
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }} style={{ textAlign: "right" }}>
        <Button variant="contained" onClick={(e) => { navigate("/MachineBillingInfo"); }} >Manage</Button>
      </Box>
    </Card>
  </Grid>)
}

export default TileMachineBillingStatus;