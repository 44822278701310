import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import { API_PATH } from "../../App";
import LoadingScreen from "../other/LoadingScreen";
import { UserGroup } from "../other/settings/UserGroup/SettingsUserGroup";
import AddUser from "../other/users/AddUser";

export interface AuthUser {
  authId: string,
  authName: string | null,
  authEmail: string,
  authFbId: string,
  authRole: string,
  userGroup: {
    id: string,
    name: string
  } | null
}

export function createAuthUser(n: any
): AuthUser {
  return n;
}

function Users() {
  const [showAddUser, setShowAddUser] = React.useState(false);
  const [userGroups, setUserGroups] = React.useState<UserGroup[]>([])
  const [isLoading, setIsLoading] = React.useState(false);
  const [authUsers, setAuthUsers] = React.useState<AuthUser[]>([]);
  const [editUser, setEditUser] = React.useState<AuthUser | null>(null);

  function showCreateUserDialog() {
    setShowAddUser(true)
  }

  function fetchUsers() {
    setIsLoading(true)
    axios.get(API_PATH + '/auth/listUsers')
      .then(function (response) {
        var result: AuthUser[] = []
        response.data.forEach((n: any) => {
          result.push(createAuthUser(n))
        })
        setAuthUsers(result)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsLoading(false)
      })
    axios.get('/' + API_PATH + '/usergroup')
      .then(function (response) {
        var res: UserGroup[] = []
        response.data.forEach((n: any) => {
          res.push(n)
        });
        setUserGroups(res)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  useEffect(() => {
    fetchUsers();
  }, [])
  return (<Grid container >
    <Grid container style={{ padding: "5px 10px 15px" }} >
      <Grid item xs={9}>
        <Typography variant="h5" component="h2">
          Users
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={showCreateUserDialog} >Add</Button>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>User Group</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {authUsers.map((row) => (
              <TableRow
                key={row.authId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.authName}
                </TableCell>
                <TableCell scope="row">
                  {row.authEmail}
                </TableCell>
                <TableCell scope="row">
                  {row.authRole}
                </TableCell>
                <TableCell scope="row">
                  {row.userGroup?.name ?? ""}
                </TableCell>
                <TableCell align="right"><a onClick={() => { setEditUser(row) }} ><EditIcon /></a></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    {(isLoading) && (<LoadingScreen />)}
    <AddUser show={showAddUser} userGroups={userGroups} onComplete={() => { fetchUsers(); setShowAddUser(false) }} user={null} />
    {(editUser != null) && (<AddUser show={true} userGroups={userGroups} onComplete={() => { fetchUsers(); setEditUser(null); }} user={editUser} />)}

  </Grid >)
}

export default Users;