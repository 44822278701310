import { useState } from "react";
import { Machine } from "../SubPages/DataCenterDetails";
import AddToUserGroupModal from "./AddToUserGroupModal";
import { UserGroup } from "./settings/UserGroup/SettingsUserGroup";
import axios from "axios";
import { API_PATH } from "../../App";
import { Snackbar } from "@mui/material";


function AddToUserGroup(props: { data: Machine | null, onClose: () => void, onSave: () => void }) {
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    function onSubmit(ug: UserGroup | null) {
        if (ug == null) {
            setErrorMessage("User Group can't be empty!")
            return;
        }
        if (props.data == null) {
            setErrorMessage("Need to select atleast one machine!")
            return;
        }
        setIsLoading(true);
        axios.post('/' + API_PATH + "/machine/add_to_group",
            {
                "macIds": [props.data.macId],
                "userGroupId": ug.id
            })
            .then((response) => {
                setIsLoading(false);
                props.onSave()
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorMessage("Error saving data!")
            });
    }
    return <>
        <Snackbar
            open={errorMessage.length > 0}
            autoHideDuration={2000}
            onClose={() => { setErrorMessage("") }}
            message={errorMessage}
            color="error"
        />
        <AddToUserGroupModal show={props.data != null} defaultVal={props.data?.userGroup?.id} isloading={isLoading} onSubmit={onSubmit} onClose={props.onClose} />
    </>
}

export default AddToUserGroup;