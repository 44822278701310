import { Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import axios from "axios";
import { API_PATH } from "../../../../App";

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Machine, createMachineFromObject } from "../../../SubPages/DataCenterDetails";
import { Theme, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import MAchineOnlineTable, { MachineBilling, MachineBillingInfo, emptyMachineBillingRow } from "./MAchineOnlineTable";
import LoadingScreen from "../../LoadingScreen";


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function getStyles(i: number, m: Machine, theme: Theme) {
  // return {
  //   fontWeight:
  //     personName.indexOf(name) === -1
  //       ? theme.typography.fontWeightRegular
  //       : theme.typography.fontWeightMedium,
  // };
  return {}
}

function MachineOnlineStatus() {

  const theme = useTheme();

  const [machineSelectOpen, setMachineSelectOpen] = useState(false);

  const [isProcessingData, setIsProcessingData] = useState<boolean>(false);

  const [dateSelected, setDateSelected] = useState<Dayjs | null>(dayjs());

  const [dataCenters, setDatacenters] = useState<{ id: string, name: string }[]>([])

  const [foundMachines, setFoundMachines] = useState<Machine[]>([])

  const [selectedMachines, setSelectedMachines] = useState<any[]>([])

  const [currentDataCenters, setCurrentDataCenters] = useState("")

  const [dataReceived, setDataReceived] = useState<Map<string, MachineBillingInfo> | null>(new Map())

  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (bottomRef.current) {
        bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }, 500);
  };

  function getDatacenterInfo() {
    setIsProcessingData(true);

    axios.get(API_PATH + '/datacenter')
      .then(function (response) {
        var res: { id: string, name: string }[] = []
        response.data.forEach((n: any) => {
          res.push({ id: n.id, name: n.centername })
        });
        setDatacenters(res);
        setIsProcessingData(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsProcessingData(false)
      })
  }
  function getMachines(id: string | undefined) {
    setIsProcessingData(true)
    axios.get('/' + API_PATH + '/datacenter/' + id + '/')
      .then(function (response) {
        var result: Machine[] = []
        let dateNow = new Date();
        response.data.machineList.forEach((n: any) => {
          result.push(createMachineFromObject(n, dateNow))
        })
        setFoundMachines(result)
        setIsProcessingData(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsProcessingData(false)
      })
  }
  function selectDatacenter(selection: string) {
    setCurrentDataCenters(selection)
    setFoundMachines([])
    setSelectedMachines([])
    if (selection.length == 0) {

    } else {
      getMachines(selection)
    }
  }

  function getMachineBillingInfo() {
    if (selectedMachines.length == 0) {
      return;
    }
    setIsProcessingData(true)
    setDataReceived(null)
    var parms: string[] = [];
    parms.push("ids=" + selectedMachines.map(n => { return foundMachines[n].macId }).join(","));
    parms.push("date=" + dateSelected?.format("YYYY-MM-DD"))
    axios.get(API_PATH + '/uptime/machine?' + parms.join("&"))
      .then(function (response) {
        processData(new Map(Object.entries(response.data)))
        setIsProcessingData(false)
        scrollToBottom()
      })
      .catch(function (error) {
        console.log(error);
        setIsProcessingData(false)
      })
  }

  function processData(updateMap: Map<string, any>) {
    var result = new Map<string, MachineBillingInfo>()
    selectedMachines.forEach(m => {
      result.set(foundMachines[m].macId, { machine: foundMachines[m], info: emptyMachineBillingRow(foundMachines[m], dateSelected?.format("YYYY-MM-DD") ?? "") })
    })
    Array.from(updateMap.entries()).map((entry) => {
      const [macId, row2] = entry;
      var data2Update = result.get(macId)
      if (data2Update != null) {
        row2.forEach((x: MachineBilling) => {
          var newRow = data2Update?.info.get(x.hour);
          if (newRow != undefined) {
            newRow.up = x.up;
            newRow.id = x.id;
            data2Update?.info.set(x.hour, newRow)
          }
        });
        result.set(macId, data2Update)
      }
    });
    setDataReceived(result)
  }
  function removeSelection(n: any) {
    var newArray = selectedMachines.slice()
    newArray.splice(newArray.indexOf(n), 1);
    setSelectedMachines(newArray);
    setDataReceived(null)
  }
  useEffect(() => {
    getDatacenterInfo()
  }, [])

  return (<Grid container style={{ padding: "10px" }} ref={bottomRef} >
    <Paper style={{ width: "100%", padding: "10px" }}>
      <Grid item xs={12}>
        <Grid container >
          <Grid item xs={12}>
            <Typography variant="h6" component="h2"> Machine online status </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }} spacing={1} >
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={dateSelected}
                  onChange={(newValue) => { setDateSelected(newValue); setDataReceived(null); }}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="datacenter-label">Datacenter</InputLabel>
              <Select
                labelId="datacenter-label"
                value={currentDataCenters}
                label="Datacenter"
                onChange={(e: SelectChangeEvent) => { selectDatacenter(e.target.value); setDataReceived(null); }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataCenters.map(n => <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={7}>
            <FormControl fullWidth >
              <InputLabel id="machine-label">Machines</InputLabel>
              <Select
                open={machineSelectOpen}
                labelId="machine-label"
                label="Machines"
                multiple
                disabled={foundMachines.length == 0}
                value={selectedMachines}
                onOpen={() => { setMachineSelectOpen(true) }}
                onClose={() => { setMachineSelectOpen(false) }}
                onChange={(e: SelectChangeEvent<typeof selectedMachines>) => {
                  const {
                    target: { value },
                  } = e;
                  var values = typeof value === 'string' ? value.split(',') : value
                  if (values.indexOf('-1') >= 0) {
                    setSelectedMachines(foundMachines.map((n, i) => i))
                    setMachineSelectOpen(false)
                    scrollToBottom()
                  } else {
                    setSelectedMachines(values);
                  }
                  setDataReceived(null);
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Machines</em>;
                  }
                  return selected.map((n) => { return foundMachines[n].workerId }).join(', ');
                }}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  <em>Machines</em>
                </MenuItem>
                <MenuItem value="-1">
                  <b>Select All</b>
                </MenuItem>
                {foundMachines.map((m, i) => (
                  <MenuItem
                    key={i}
                    value={i}
                    style={getStyles(i, m, theme)}
                  >
                    {m.workerId}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: "10px", paddingBottom: "10px" }} >
          {selectedMachines.map((n) => { return <Chip key={n} label={foundMachines[n].workerId} size="small" variant="outlined" onDelete={() => { removeSelection(n) }} /> })}
          {(selectedMachines.length > 0) && (<Chip label="Clear all" size="small" color="warning" onDelete={() => {
            setSelectedMachines([]);
            setDataReceived(null);
          }} />)}
        </Grid>
        <Grid container justifyContent="flex-end" >
          <Grid item xs={12} sm={3} md={2} >
            <FormControl fullWidth >
              <Button variant="contained" endIcon={<SearchIcon />} onClick={getMachineBillingInfo} > Find </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    {(dataReceived != null && dataReceived.size > 0) && (<MAchineOnlineTable reSubmit={getMachineBillingInfo} dateSelected={dateSelected} dataReceived={dataReceived} />)}
    {(isProcessingData) && (<LoadingScreen />)}
  </Grid>)
}

export default MachineOnlineStatus;