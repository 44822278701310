import axios from 'axios';
var url = require('url');

export function validatePoolURL(urlString: string): boolean {
    if (urlString == undefined || urlString == null) {
        return false;
    }
    let urlx;
    try {
        urlx = new URL(urlString);
    } catch (_) {
        return false;
    }
    return urlx.protocol === "http:" || urlx.protocol === "https:";
}


export function poolUrlInfo(urlString: string) {
    var url_parts = url.parse(urlString, true);
    console.log(url_parts)
    let username = url_parts.query.user_name;
    var currency = undefined
    let path = url_parts.pathname
    let path_frags = path.split("/")
    if (path_frags[1] != undefined && path_frags[1].startsWith("mining-user")) {
        let path_frags_2 = path_frags[1].split("-")
        if (path_frags_2[2] != undefined) {
            currency = path_frags_2[2];
        } else {
            currency = "BTC"
        }
    }
    return { username: username, currency: currency }
}

export function formatNumber(n: any) {
    return (Math.round(n * 100) / 100).toFixed(2);
}