import { useEffect, useState } from "react";
import { Machine, timeElapsedSince } from "../../SubPages/DataCenterDetails";

function MachineInfoAditionalStatusInfo(props: { data: Machine }) {
    const { data } = props
    const [statusText, setStatusText] = useState<string>("");
    useEffect(() => {
        if ((data.additionalStatusTime) && (data.additionalStatus)) {
            // var d = new Date(data.additionalStatusTime)
            // var dateString = (d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear());
            var dateString = timeElapsedSince(Number(data.additionalStatusTime ?? 0));
            switch (data.additionalStatus) {
                case "MACHINE_SENT_TO_REPAIR": setStatusText("Sent to repair on " + dateString + ""); break;
                case "MACHINE_ACTIVE": setStatusText("Active since " + dateString + ""); break;
                case "MACHINE_REPAIR_REQUIRED": setStatusText("Repair required since " + dateString + ""); break;
            }
        }
    }, []);
    return <>{(statusText != "") && (<><small>{statusText}</small><br /></>)}</>
}

export default MachineInfoAditionalStatusInfo;