import React, { useEffect } from "react";
import { Machine, MachineConfig, timeElapsed } from "../SubPages/DataCenterDetails";
import { TableRow } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MachineHistory from "./MachineHistory";
import { Button, Chip, TableCell } from "@mui/material";
import MachineInfoWithHealth from "./machine/MachineInfoWithHealth";
import MachineSettings from "./MachineSettings";


function MachineInfoRow(props: { data: Machine, firstElement?: React.ReactNode | null, datacenterName?: string | null, refresh: () => void, machineConfigs: MachineConfig[], showHistory: boolean, showSettings?: boolean }) {

    const { data, firstElement } = props

    useEffect(() => {
    });
    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={data.id} style={{ lineHeight: "15px" }} className={
        (data.disable) ? "disabledMachine" :
            ((data.repairing) || (data.additionalStatus === "MACHINE_REPAIR_REQUIRED") || (data.additionalStatus === "MACHINE_SENT_TO_REPAIR")) ? "repairingMachine" :
                (data.isSnoozed) ? "snoozedMachine" :
                    (data.health?.hasError) ? "errorMachine" :
                        (data.health?.hasWarning) ? "warningMachine" : "goodMachine"
                    } >
        {(firstElement) && (<TableCell style={{ padding: "0px" }} > {firstElement}
        </TableCell>
        )}

        <TableCell>
            <MachineInfoWithHealth data={props.data} showHistory={props.showHistory} />
        </TableCell>
        <TableCell style={{ padding: "0px" }}>
            <ContentCopyIcon style={{ width: "15px", height: "15px", cursor: "pointer", float: "left" }} onClick={() => { navigator.clipboard.writeText(data.workerId) }} />
        </TableCell>
        <TableCell>
            <span style={{ fontSize: "11px", display: "block" }}>{(data.machineConfig?.model && data.machineConfig?.model != 'unknown') ? data.machineConfig?.model : '[' + data.model + ']'}</span>
            {(data.serialNo.length > 0) && (<span style={{ fontSize: "11px", display: "block" }} >{data.serialNo}</span>)}
            <i style={{ fontSize: "11px", display: "block" }} >{data.macId}</i>
            <span style={{ fontSize: "11px", display: "block" }}>Machine ID : {data.id}</span>
            <span style={{ fontSize: "11px", display: "block" }}>Coin : {data.stratum?.coin}</span>
            <span style={{ fontSize: "11px", display: "block" }}>Serial No : {data.serialNo? data.serialNo : "Not found"}</span>
            {(data.url) && (data.url.trim().length > 0) && (<span style={{ fontSize: "11px", display: "block" }}>  {data.url} </span>)}
            <i style={{ fontSize: "11px" }}>{timeElapsed(data.health)}</i>
        </TableCell>
        <TableCell>
            {(data.hostingCost != null) && (<span style={{ fontSize: "11px", display: "block" }}>{data.hostingCost} $ /hour</span>)}
        </TableCell>
        <TableCell>
            {(data.userGroup) && (<><b style={{ fontSize: "11px" }}>  {data.userGroup.name} </b> <br /> </>)}
            {(props.datacenterName) && (<b style={{ fontSize: "11px" }}>{props.datacenterName}</b>)}
        </TableCell>
        {(props.showSettings == undefined || props.showSettings) && (<TableCell><MachineSettings data={props.data} refresh={props.refresh} machineConfigs={props.machineConfigs} /></TableCell>)}
    </TableRow>)
}

export default MachineInfoRow;
