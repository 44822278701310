import { Machine } from "../Pages/SubPages/DataCenterDetails"

export function sortMachineData(result: Machine[]): Machine[] {
    return ((result.sort(sortWithTime)).sort((a, b) =>
        (a.disable) ? 1 : (b.disable) ? -1 :
            isMachineRepairing(a) ? 1 : isMachineRepairing(b) ? -1 :
                isMachineRequireRepairing(a) ? 1 : isMachineRequireRepairing(b) ? -1 :
                    ((machineHasError(a) && !a.isSnoozed) ? -1 : (machineHasError(b) && !b.isSnoozed) ? 1 :
                        a.isSnoozed ? -1 : b.isSnoozed ? 1 :
                            (a.health?.hasWarning) ? -1 : (b.health?.hasWarning) ? 1 :
                                (Number(a.health?.hashRate ?? 0) < Number(b.health?.hashRate ?? 0)) ? -1 : 0)))
}

export function sortWithTime(a: Machine, b: Machine): number {
    return getMachineSortTime(b) - getMachineSortTime(a)
}

export function getMachineSortTime(a: Machine): number {
    return Number(
        (
            (isMachineRepairing(a) || isMachineRequireRepairing(a)) ? a.additionalStatusTime :
                (machineHasError(a) ? a.errorStatus?.triggerTime : a.health?.lastFetchTime)
        )
        ?? 0)
}

function machineHasError(m: Machine): boolean {
    return m.health?.hasError ?? false;
}

function isMachineRepairing(m: Machine): boolean {
    return (m.repairing == true) ? true : (m.additionalStatus == "MACHINE_SENT_TO_REPAIR")
}
function isMachineRequireRepairing(m: Machine): boolean {
    return (m.additionalStatus == "MACHINE_REPAIR_REQUIRED")
}

export function sortResultData(d: Machine[] | null, order: string): Machine[] | null {
    if (d != null && order != SORT_ORDER.NONE) {
        switch (order) {
            case SORT_ORDER.WID_DESC:
                return d.sort((a, b) => { return compareStrings(a.workerId, b.workerId) })
            case SORT_ORDER.WID_ASC:
                return d.sort((a, b) => { return compareStrings(b.workerId, a.workerId) })
            case SORT_ORDER.LU_ASC:
                return d.sort((a, b) => { return (a.health?.lastFetchTime ?? 0) - (b.health?.lastFetchTime ?? 0) })
            case SORT_ORDER.LU_DESC:
                return d.sort((a, b) => { return (b.health?.lastFetchTime ?? 0) - (a.health?.lastFetchTime ?? 0) })
            case SORT_ORDER.MODEL:
                return d.sort((a, b) => { return compareStrings(a.model, b.model) })
            case SORT_ORDER.DATACENTER:
                return d.sort((a, b) => { return compareStrings(a.centerId, b.centerId) })
            case SORT_ORDER.USER_GROUP_ASC:
                return d.sort((a, b) => { return compareStrings(a.userGroup?.name ?? "", b.userGroup?.name ?? "") })
            case SORT_ORDER.USER_GROUP_DESC:
                return d.sort((a, b) => { return compareStrings(b.userGroup?.name ?? "", a.userGroup?.name ?? "") })
        }
    }
    return d
}

function compareStrings(a: string, b: string): number {
    console.log(a, b)
    let x = a.toString().toLowerCase();
    let y = b.toString().toLowerCase();
    if (x < y) { return -1; }
    if (x > y) { return 1; }
    return 0;
}

export const SORT_ORDER = {
    MODEL: 'MODEL',
    DATACENTER: 'DATACENTER',
    USER_GROUP_DESC: 'USER_GROUP',
    USER_GROUP_ASC: 'USER_GROUP_ASC',
    WID_DESC: 'WID_DESC',
    WID_ASC: 'WID_ASC',
    LU_ASC: 'LU_ASC',
    LU_DESC: 'LU_DESC',
    NONE: ''
};