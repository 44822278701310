import { Grid, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from "react";

function NotFound() {
  return (<Grid container style={{ display: "flex", flexDirection: "row", textAlign: "center", minHeight: "60vh" }} >
    <Grid item xs={12}>&nbsp;</Grid>
    <Grid item xs={12}>
      <Typography variant="h1" component="h2">
        Not Found
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <p>The page you are looking for is not found!</p>
    </Grid>
  </Grid>)
}

export default NotFound;