import { Grid, Card, CardContent, Typography, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../../../App";
import { styled } from "@mui/material/styles";


enum HealthStatus {
    OK = "OK",
    CONFIG_ERROR = "CONFIG_ERROR",
    ERROR = "ERROR",
    UNKNOWN = "UNKNOWN"
}

interface HealthData {
    [key: string]: string | any;
}

interface DetailedHealthItem {
    healthStatus: HealthStatus;
    [key: string]: any;
}

const ExpandMore = styled((props: any) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }: any) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

function HealthCheckPage() {
    const [healthData, setHealthData] = useState<HealthData | any>(null);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState<string | null>(null);

    useEffect(() => {
        const fetchHealthData = async () => {
            try {
                const response = await axios.get("/" + API_PATH + "/healthcheck");
                const data = response.data;

                const promises = Object.entries(data).map(async ([key, url]) => {
                    if (typeof url === "string") {
                        const res = await axios.get(url);
                        return { [key]: res.data };
                    }
                });

                const results = await Promise.all(promises);
                const combinedResults = results.reduce((acc, curr) => ({ ...acc, ...curr }), {});

                setHealthData(combinedResults);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchHealthData();
    }, []);

    const handleExpandClick = (key: string) => {
        setExpanded(expanded === key ? null : key);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Grid container spacing={2}>
            {healthData &&
                Object.entries(healthData).map(([key, value]) => (
                    <Grid item xs={12} key={key}>
                        <Card>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item xs={10}>
                                        <Typography variant="h6">{key}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ExpandMore
                                            expand={expanded === key}
                                            onClick={() => handleExpandClick(key)}
                                            aria-expanded={expanded === key}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </ExpandMore>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Collapse in={expanded === key} timeout="auto" unmountOnExit>
                                <CardContent>
                                    {Array.isArray(value) &&
                                        value.map((item: DetailedHealthItem, index: number) => (
                                            <Card key={index} sx={{ marginBottom: 2 }}>
                                                <CardContent>
                                                    <Grid container justifyContent="space-between">
                                                        <Grid item xs={9}>
                                                            {Object.entries(item).map(([itemKey, itemValue]) => (
                                                                itemKey !== "healthStatus" && (
                                                                    <Typography variant="body1" key={itemKey}>
                                                                        {itemKey}: {itemValue}
                                                                    </Typography>
                                                                )
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="body1" align="right">
                                                                <strong>{item.healthStatus}</strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        ))}
                                </CardContent>
                            </Collapse>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
}

export default HealthCheckPage;
