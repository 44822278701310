import React, { useEffect, useState } from "react";
import { Machine } from "../SubPages/DataCenterDetails";
import { Button, TextField, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../App";
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MachineAddComment from "./machine/MachineAddComment";


interface machineHistory {
  userId: string;
  message: string;
  messageTime: number;
  action: string
}

export function createHistory(
  userId: string,
  message: string,
  messageTime: number,
  action: string
): machineHistory {
  return { userId, message, messageTime, action };
}

function MachineHistory(props: { onClose: () => void, data: Machine }) {
  const { onClose, data } = props

  const [rows, setRows] = useState<machineHistory[]>([]);
  const [filtered, setFiltered] = useState<machineHistory[]>([]);

  const [addComment, setAddComment] = useState(false);

  const [hideSystem, setHideSystem] = useState(true);
  const [hideUserComment, setHideUserComment] = useState(false);
  const [hideEnableDisable, setHideEnableDisable] = useState(false);
  const [hideSnoozing, setHideSnoozing] = useState(false);
  const [hideRestart, setHideRestart] = useState(false);
  const [hidePool, setHidePool] = useState(false);
  const [hideIP, setHideIp] = useState(false);
  const [hideUserGroup, setHideUserGroup] = useState(false);


  const [refreshData, setRefreshData] = useState<any>(null);

  function getHistory() {
    setRows([])
    axios.get('/' + API_PATH + '/machine/' + data.id + '/history')
      .then(function (response) {
        var newHistory: machineHistory[] = []
        response.data.forEach((n: any) => {
          newHistory.push(createHistory(n.userId, n.message, n.messageTime, n.action))
        });
        setRows(newHistory)
        setFiltered([])
        updateFilter()
      })
      .catch(function (error) {
        // handle error

      })
  }
  function updateFilter() {
    setRefreshData(Math.random)
  }
  useEffect(() => {
    getHistory()
  }, [])


  useEffect(() => {
    if (refreshData != null) {
      setFiltered(rows.filter((n) => {
        return (!hideSystem || (n.userId != "system" && n.userId != "SYSTEM")) && (
          (!hideUserComment || n.action != "USER_COMMENT") &&
          (!hideEnableDisable || !(n.action == "ENABLED" || n.action == "DISABLED")) &&
          (!hideSnoozing || !(n.action == "SNOOZE_DISABLED" || n.action == "SNOOZE_ENABLED")) &&
          (!hideRestart || !(n.action == "RESTART_REQUESTED" || n.action == "RESTART")) &&
          (!hidePool || n.action != "POOL_CHANGED") &&
          (!hideUserGroup || n.action != "USER_GROUP_UPDATED") &&
          (!hideIP || n.action != "IP_CHANGED")
        )
      }))
    }
  }, [refreshData])

  return (<Dialog open={true} onClose={() => { onClose() }} fullWidth maxWidth="md" >
    <DialogTitle style={{ textAlign: "center" }}>  Machine  History </DialogTitle>
    <DialogContent style={{ padding: "0px 3px 15px 3px", fontSize: "14px" }}>
      <Grid container >
        <Grid item xs={12} style={{ textAlign: "center" }} >
          <span style={{ wordBreak: "break-all", padding: "5px" }} > {data.workerId} </span>
          <ContentCopyIcon style={{ width: "15px", height: "15px", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(data.workerId) }} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center", fontSize: "16px" }} >
          <b>Hide : </b>
          <FormControlLabel
            label="User Comments"
            control={<Checkbox checked={hideUserComment} onChange={e => { setHideUserComment(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="Enable/Disable"
            control={<Checkbox checked={hideEnableDisable} onChange={e => { setHideEnableDisable(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="Snoozing"
            control={<Checkbox checked={hideSnoozing} onChange={e => { setHideSnoozing(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="Restart"
            control={<Checkbox checked={hideRestart} onChange={e => { setHideRestart(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="Pool"
            control={<Checkbox checked={hidePool} onChange={e => { setHidePool(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="IP"
            control={<Checkbox checked={hideIP} onChange={e => { setHideIp(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="User Group"
            control={<Checkbox checked={hideUserGroup} onChange={e => { setHideUserGroup(e.target.checked); updateFilter(); }} />}
          />
          <FormControlLabel
            label="System"
            control={<Checkbox checked={hideSystem} onChange={e => { setHideSystem(e.target.checked); updateFilter(); }} />}
          />
        </Grid>
        <Grid item xs={12} style={{ fontSize: "12px" }} >
          {(rows.length > 0) && (
            <TableContainer component={Paper} style={{ margin: "10px 0" }} >
              <Table size="small" className="tightTable" >
                <TableHead>
                  <TableRow>
                    <TableCell> Message </TableCell>
                    <TableCell style={{ minWidth: "100px" }}> Time </TableCell>
                    <TableCell> User / Action </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtered.map((n, i) => {
                    var d = new Date(Number(n.messageTime))
                    return (<TableRow key={i}>
                      <TableCell style={{ wordBreak: "break-all" }}>{n.message}</TableCell>
                      <TableCell>
                        <span>
                          {
                            [d.getMonth() + 1,
                            d.getDate(),
                            d.getFullYear()].join('/')
                          } <br />
                          {
                            [d.getHours(),
                            d.getMinutes(),
                            d.getSeconds()].join(':')
                          }
                        </span>
                      </TableCell>
                      <TableCell style={{ wordBreak: "break-all" }}>{n.userId} <br /> {n.action}</TableCell>
                    </TableRow>)
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      {(rows.length == 0) && (<div style={{ textAlign: "center", padding: "10px" }}>
        <CircularProgress />
      </div>)}
    </DialogContent>
    <DialogActions style={{ justifyContent: "space-between" }}>
      <Button onClick={() => { setAddComment(true) }}> ADD COMMENT  </Button>
      <Button onClick={() => { onClose() }}>Close</Button>
    </DialogActions>
    {addComment && (<MachineAddComment data={data} onChange={() => { setAddComment(false); getHistory(); }} onCancel={() => { setAddComment(false) }} />)}
  </Dialog>);
}

export default MachineHistory;