import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";

export interface FBuser {
    id: string;
    email: string;
    token: string;
}

const firebaseConfig = {
    apiKey: "AIzaSyDHlyDPXlO_lcDI3r864VUjbxgBJ9wy_5E",
    authDomain: "segments-ae.firebaseapp.com",
    projectId: "segments-ae",
    storageBucket: "segments-ae.appspot.com",
    messagingSenderId: "980518508149",
    appId: "1:980518508149:web:88d35b8a2dd90474af0a3c",
    measurementId: "G-PPYTB7FK9Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
export function logOut(success: () => void) {
    signOut(auth).then(() => {
        // Sign-out successful.
        console.log("Signed out successfully")
    }).catch((e: any) => {
        // An error happened.
    });
}

export function signedUser(callback: (user: FBuser | null) => void) {
    refreshSignedUser(callback, false);
}

export function refreshSignedUser(callback: (user: FBuser | null) => void, refresh: boolean) {
    auth.currentUser?.getIdToken(refresh).then(tokenFound => {
        callback({ id: auth.currentUser?.uid ?? "", email: auth.currentUser?.email ?? "", token: tokenFound })
    }).catch((err) => { callback(null) })
}
