import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_PATH } from "../../App";
import { DataCenter, createDataCenter } from "./DataCenters";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export interface TelegramBot {
    bot_id: string;
    name: string;
    botKey: string;
    chatId: string;
    centerId: string;
}

export function createTelegramBot(
    bot_id: string,
    name: string,
    botKey: string,
    chatId: string,
    centerId: string,
): TelegramBot {
    return { bot_id: bot_id, name, botKey, chatId, centerId };
}
function TelegramBots() {
    const [isDatacenterLoaded, setIsDatacenterLoaded] = useState(false);
    const [datacenters, setDatacenters] = useState<DataCenter[]>([]);
    const [datacenterMap, setDatacenterMap] = useState(new Map<string, DataCenter>());
    const [bots, setBots] = useState<TelegramBot[]>([]);
    const [botToEdit, setBotToEdit] = useState<TelegramBot | null>(null);
    const [botToDelete, setBotToDelete] = useState<TelegramBot | null>(null);
    const [isProcessingData, setIsProcessingData] = useState(false);

    function getDataCenters() {
        setIsDatacenterLoaded(false)
        axios.get(API_PATH + '/datacenter')
            .then(function (response) {
                // handle success
                var result: DataCenter[] = []
                var dcMap = new Map<string, DataCenter>();
                response.data.forEach((n: any) => {
                    var dc = createDataCenter(n.id, n.centername, n.disable, null, n.total_machines, n.engineerid, n.bot_key, n.chat_id, n.restart, n.zen_key)
                    result.push(dc)
                    dcMap.set(dc.id, dc)
                })
                setDatacenters(result)
                setDatacenterMap(dcMap)
                setIsDatacenterLoaded(true)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setIsDatacenterLoaded(true)
            })
    }
    function getAllBots() {
        axios.get(API_PATH + '/telegram')
            .then(function (response) {
                var result: TelegramBot[] = []
                response.data.forEach((n: any) => {
                    result.push(createTelegramBot(n.bot_id, n.name, n.botKey, n.chatId, n.centerId))
                });
                setBots(result);
                console.log(result);
            })
            .catch(function (error) {

            })

    }
    function processData(d: TelegramBot) {
        setIsProcessingData(true)
        if (d.bot_id == "") {
            axios.post(API_PATH + '/telegram', JSON.parse(JSON.stringify(d)))
                .then(function (response) {
                    setBotToEdit(null)
                    setIsProcessingData(false);
                    getAllBots()
                })
                .catch(function (error) {
                    console.log(error);
                    setIsProcessingData(false);
                });
        } else {
            axios.put(API_PATH + '/telegram', JSON.parse(JSON.stringify(d)))
                .then(function (response) {
                    setBotToEdit(null)
                    setIsProcessingData(false);
                    getAllBots()
                })
                .catch(function (error) {
                    console.log(error);
                    setIsProcessingData(false);
                });

        }
    }
    function deleteBot(d: TelegramBot) {
        setIsProcessingData(true)
        axios.delete(API_PATH + '/telegram', { data: JSON.parse(JSON.stringify(d)) })
            .then(function (response) {
                setIsProcessingData(false);
                getAllBots()
                setBotToDelete(null)
            })
            .catch(function (error) {
                console.log(error);
                setIsProcessingData(false);
            });
    }
    useEffect(() => {
        getDataCenters()
        getAllBots()
    }, [])
    return <Grid container>
        <Dialog
            open={botToDelete != null}
            onClose={() => { setBotToDelete(null) }}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    deleteBot(botToDelete!)
                },
            }}
            fullWidth={true}
            maxWidth="sm" >
            <DialogTitle>Delete Telegram bot</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Confirm delete bot {botToDelete?.bot_id} {botToDelete?.name}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setBotToDelete(null) }} disabled={isProcessingData}>Cancel</Button>
                <Button type="submit" disabled={isProcessingData}>Delete</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={botToEdit != null}
            onClose={() => { setBotToEdit(null) }}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const n = Object.fromEntries((formData as any).entries());
                    processData(createTelegramBot(botToEdit?.bot_id ?? "", n.name, n.botKey, n.chatId, n.centerId))
                },
            }}
            fullWidth
            maxWidth="md" >
            <DialogTitle>Telegram Bots</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    defaultValue={botToEdit?.name}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="botKey"
                    label="Bot Key"
                    fullWidth
                    variant="standard"
                    defaultValue={botToEdit?.botKey}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="chatId"
                    label="Chat Id"
                    fullWidth
                    variant="standard"
                    defaultValue={botToEdit?.chatId}
                />
                <TextField
                    select
                    margin="dense"
                    label="Datacenter"
                    fullWidth
                    required
                    defaultValue={botToEdit?.centerId}
                    name="centerId"
                >
                    <MenuItem value=""> None </MenuItem>
                    {datacenters.map((n) => (
                        <MenuItem key={n.id} value={n.id}> {n.centername} </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setBotToEdit(null) }} disabled={isProcessingData}>Cancel</Button>
                <Button type="submit" disabled={isProcessingData}>Save</Button>
            </DialogActions>
        </Dialog>
        <Grid container style={{ padding: "20px" }} >
            <Grid item xs={9}>
                <Typography variant="h4" component="h2">
                    Telegram Chanel Bots
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={() => { setBotToEdit(createTelegramBot("", "", "", "", "")) }} >Add</Button>
            </Grid>
        </Grid>
        <Grid container style={{ padding: "20px" }} >
            {(bots?.length == 0) && (<Grid item xs={12} style={{ textAlign: "center", padding: "40px 0px" }}><span>None</span></Grid>)}
            {(bots?.length > 0) && (
                <Grid item xs={12} style={{ textAlign: "center", padding: "40px 0px" }}>

                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell> #</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell >Datacenter</TableCell>
                                    <TableCell > Actions </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bots.map((row) => (
                                    <TableRow
                                        key={row.bot_id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.bot_id}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>
                                            {(datacenterMap.get(row.centerId) != undefined) && (datacenterMap.get(row.centerId)?.centername)}
                                            {(datacenterMap.get(row.centerId) == undefined) && (row.chatId)}

                                        </TableCell>
                                        <TableCell>
                                            <a onClick={() => { setBotToDelete(row) }}>
                                                <DeleteIcon fontSize="small" style={{ cursor: "pointer" }} />
                                            </a>
                                            &nbsp;  &nbsp;
                                            <a onClick={() => { setBotToEdit(row) }}>
                                                <EditIcon fontSize="small" style={{ cursor: "pointer" }} />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>)}
        </Grid>
    </Grid>
}

export default TelegramBots
