import { Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsUserGroupAdd from "./SettingsUserGroupAdd";
import { API_PATH } from "../../../../App";
import SettingsUserGroupDelete from "./SettingsUserGroupDelete";
import { F2Pool } from "../Pool/SettingsPool";

export interface UserGroup {
  id: string;
  name: string;
  f2Pools: F2Pool[]
}
export function createUserGroup(n: any | null): UserGroup | null { return n; }
function SettingsUserGroup(props: { onclose: () => void }) {
  const { onclose } = props
  const [newUserGroup, setNewUserGroup] = useState<boolean>(false);
  const [isProcessingData, setIsProcessingData] = useState<boolean>(false);

  const [rows, setRows] = useState<UserGroup[]>([])
  const [userGroupToEdit, setUserGroupToEdit] = useState<UserGroup | null>(null)
  const [userGroupToDelete, setUserGroupToDelete] = useState<UserGroup | null>(null)

  function listAll() {
    setIsProcessingData(true);

    axios.get('/' + API_PATH + '/usergroup')
      .then(function (response) {
        var res: UserGroup[] = []
        response.data.forEach((n: any) => {
          res.push(n)
        });
        setRows(res);
        setIsProcessingData(false)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  useEffect(() => {
    listAll()
  }, [])

  return (<Grid container style={{ padding: "10px" }} >

    <Grid item xs={12}>
      <Grid container><div style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }} onClick={e => { onclose() }} ><CloseIcon /></div></Grid>
      <Grid container >
        <Grid item xs={9}>
          <Typography variant="h6" component="h2"> User Group </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={() => { setNewUserGroup(true) }} >Add</Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Pool</TableCell>
              <TableCell> - </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >{row.id}</TableCell>
                <TableCell style={{ minWidth: "100px" }} >{row.name}</TableCell>
                <TableCell >{row.f2Pools.length}</TableCell>
                <TableCell align="right" style={{ minWidth: "100px" }}>
                  <a onClick={e => { setUserGroupToEdit(row) }} style={{ cursor: "pointer" }} ><EditNoteIcon /></a> &nbsp;  &nbsp;
                  <a onClick={e => { setUserGroupToDelete(row) }} style={{ cursor: "pointer" }} ><DeleteIcon /></a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    {(newUserGroup) && (<SettingsUserGroupAdd data={null} onSave={() => { setNewUserGroup(false); listAll(); }} onClose={() => { setNewUserGroup(false) }} />)}
    {(userGroupToEdit != null) && (<SettingsUserGroupAdd data={userGroupToEdit} onSave={() => { setUserGroupToEdit(null); listAll(); }} onClose={() => { setUserGroupToEdit(null) }} />)}
    {(isProcessingData) && (<div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "200px", background: "rgba(255,255,255,0.8)", zIndex: 1300 }}><Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">  <CircularProgress /> </Box></div>)}
    {(userGroupToDelete != null) && (<SettingsUserGroupDelete afterDelete={() => { setUserGroupToDelete(null); listAll() }} onClose={() => { setUserGroupToDelete(null) }} data={userGroupToDelete} />)}
  </Grid>)
}

export default SettingsUserGroup;