import { useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, Card, CardContent, Typography, Radio, FormControlLabel, CircularProgress } from "@mui/material";
import { Machine } from "../../SubPages/DataCenterDetails";

function MergeMachines(props: { data: Machine, onClose: () => void, afterUpdate?: () => void }) {
    const [machineId, setMachineId] = useState("");
    const [machineToMerge, setMachineToMerge] = useState<Machine | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
    const [notSelectedMachine, setNotSelectedMachine] = useState<Machine | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const onSearchMachine = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/segments/machine/search_machine?key=${machineId}&stateType=&datacenters=&userGroups=`);
            const machines = response.data;
            if (machines.length !== 1) {
                setErrorMessage("Invalid machine ID or multiple machines found");
                setMachineToMerge(null);
            } else {
                setMachineToMerge(machines[0]);
            }
        } catch (error) {
            setErrorMessage("Error fetching machine details");
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = () => {
        if (!selectedMachine) {
            setErrorMessage("Please select the active machine");
            return;
        }
        setShowConfirmation(true);
    };

    const handleConfirmationClose = () => {
        setShowConfirmation(false);
    };

    const handleConfirmMerge = async () => {
        if (!selectedMachine || !machineToMerge) return;
        setIsLoading(true);
        try {
            const url = `/segments/machine/${selectedMachine.id}/merge`;
            await axios.post(url, null, {
                params: {
                    duplicateMachineId: notSelectedMachine?.id
                }
            });
            if (props.afterUpdate) {
                props.afterUpdate();
            }
            props.onClose();
        } catch (error) {
            setErrorMessage("Error merging machines");
        } finally {
            setIsLoading(false);
            setShowConfirmation(false);
        }
    };

    const renderMachineDetails = (machine: Machine | null, title: string, isSelected: boolean) => (
        <CardContent>
            <Typography variant="h6">{title}</Typography>
            {machine ? (
                <>
                    <Typography>Mac Id : {machine.macId}</Typography>
                    <Typography>Machine Id : {machine.id}</Typography>
                </>
            ) : (
                <Typography>Select the machine</Typography>
            )}
        </CardContent>
    );

    const getNewMachine = () => {
        if (selectedMachine?.id === props.data.id) {
            return renderMachineDetails(selectedMachine, "Active Machine", true);
        } else if (selectedMachine?.id === machineToMerge?.id) {
            return renderMachineDetails(machineToMerge, "Active Machine", true);
        } else {
            return renderMachineDetails(null, "Select the machine", false);
        }
    };

    const getOldMachine = () => {
        if (selectedMachine?.id === props.data.id) {
            return renderMachineDetails(machineToMerge, "Non Active Machine", false);
        } else if (selectedMachine?.id === machineToMerge?.id) {
            return renderMachineDetails(props.data, "Non Active Machine", false);
        } else {
            return renderMachineDetails(null, "Select the machine", false);
        }
    };

    return (
        <>
            <Snackbar
                open={errorMessage.length > 0}
                autoHideDuration={2000}
                onClose={() => setErrorMessage("")}
                message={errorMessage}
                color="error"
            />
            <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="md">
                <DialogTitle>Merge Machines</DialogTitle>
                <DialogContent>
                    {isLoading && <CircularProgress />}

                    <Card variant="outlined" style={{ marginBottom: 20 }}>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Typography variant="h6">Current Machine</Typography>
                                    <Typography>Worker ID: {props.data.workerId}</Typography>
                                    <Typography>Machine ID: {props.data.id}</Typography>
                                    <Typography>Serial No: {props.data.serialNo}</Typography>
                                    <Typography>MacId: {props.data.macId}</Typography>
                                </div>
                                <FormControlLabel
                                    control={<Radio
                                        checked={selectedMachine === props.data}
                                        onChange={() => {
                                            setSelectedMachine(props.data);
                                            setNotSelectedMachine(machineToMerge);
                                        }}
                                    />}
                                    label="Active"
                                    style={{ marginLeft: 'auto' }}
                                />
                            </div>
                        </CardContent>
                    </Card>

                    <TextField
                        label="Machine ID / MAC ID to Merge"
                        variant="outlined"
                        fullWidth
                        value={machineId}
                        onChange={(e) => setMachineId(e.target.value)}
                        style={{ marginBottom: 20 }}
                    />
                    <Button variant="contained" color="primary" onClick={onSearchMachine} disabled={isLoading}>
                        Get
                    </Button>

                    {machineToMerge && (
                        <Card variant="outlined" style={{ marginTop: 20 }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Typography variant="h6">Machine to Merge</Typography>
                                        <Typography>Worker ID: {machineToMerge.workerId}</Typography>
                                        <Typography>Machine ID: {machineToMerge.id}</Typography>
                                        <Typography>Serial No: {machineToMerge.serialNo}</Typography>
                                        <Typography>Mac Id: {machineToMerge.macId}</Typography>
                                    </div>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={selectedMachine === machineToMerge}
                                            onChange={() => {
                                                setSelectedMachine(machineToMerge);
                                                setNotSelectedMachine(props.data);
                                            }}
                                        />}
                                        label="Active"
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={onSubmit} disabled={isLoading}>Merge</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showConfirmation} onClose={handleConfirmationClose} fullWidth maxWidth="sm">
                <DialogTitle>Confirm Merge</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to merge?
                    </Typography>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Card variant="outlined" style={{ flex: 1, marginRight: 10, backgroundColor: '#d4edda' }}>
                            {getNewMachine()}
                        </Card>
                        <Card variant="outlined" style={{ flex: 1, backgroundColor: '#f8d7da' }}>
                            {getOldMachine()}
                        </Card>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose}>Cancel</Button>
                    <Button onClick={handleConfirmMerge} color="primary" disabled={isLoading}>Continue</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MergeMachines;
