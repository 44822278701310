import { Alert, Box, Button, CircularProgress, Grid, Modal, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect } from "react";
import axios from 'axios';
import { API_PATH } from "../../App";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DataCenterEdit from "../other/DataCenterEdit";
import LoadingScreen from "../other/LoadingScreen";
import { FBuser, signedUser } from "../../Helpers/Auth";
import DatacenterInfoElement from "../other/DatacenterInfoElement";

interface Column {
  id: 'id' | 'centername' | 'disable';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'id', label: 'Code', minWidth: 50 },
  { id: 'centername', label: 'Name', minWidth: 170 },

];

export interface DataCenter {
  id: string;
  centername: string;
  disable: boolean;
  info: DataCenterInfo | null;
  total_machines: string | null;
  engineerid: string | null;
  bot_key: string | null;
  chat_id: string | null;
  restart: boolean;
  zen_key: string | null;
}

export function createDataCenter(
  id: string,
  centername: string,
  disable: boolean,
  info: DataCenterInfo | null,
  total_machines: string | null,
  engineerid: string | null,
  bot_key: string | null,
  chat_id: string | null,
  restart: boolean,
  zen_key: string | null
): DataCenter {
  return { id, centername, disable, info, total_machines, engineerid, bot_key, chat_id, restart, zen_key };
}


const Modalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #444',
  boxShadow: 24,
  p: 4,
};


export interface DataCenterInfo {
  id: string,
  total: number;
  disabled: number;
  hasError: number;
  hasWarning: number;
  snoozed: number;
  onRepair: number;
  repairRequired: number;
}

function DataCenters() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [rows, setRows] = React.useState<DataCenter[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [deleteDatacenterSelected, setDatacenterToDelete] = React.useState<DataCenter | null>(null);

  const [alertElm, setAlertElm] = React.useState<any | null>(null);

  const [datacenterSelectedToStateCange, setDatacenterSelectedToStateCange] = React.useState<DataCenter | null>(null);

  const [dataCenterToEdit, setDataCenterToEdit] = React.useState<DataCenter | null>(null);

  const [loginUser, setLoginUser] = React.useState<FBuser | null>(null);

  const [datacenterInfo, setDatacenterInfo] = React.useState<DataCenterInfo[]>([]);

  var datacenterData = new Map<string, DataCenterInfo>()

  var dateNow = new Date();

  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function editDatacenter(data: DataCenter) {
    setDataCenterToEdit(data)
  }

  function confirmDatacenterStatusChange() {
    if (datacenterSelectedToStateCange == null) {
      return;
    }
    var x = datacenterSelectedToStateCange;
    x.disable = !datacenterSelectedToStateCange.disable
    setIsLoading(true)
    setDatacenterSelectedToStateCange(null)

    axios.put(API_PATH + '/datacenter', x)
      .then(function (response) {
        getDataCenters()
        setAlertElm(<Alert onClose={() => { setAlertElm(null) }}>Updaed</Alert>)
      })
      .catch(function (error) {
        console.log(error);
        getDataCenters()
        setAlertElm(<Alert onClose={() => { setAlertElm(null) }} severity="error">Failed to update</Alert>)
      });
  }
  function startEnableDatacenter(n: DataCenter, status: boolean) {
    setDatacenterSelectedToStateCange(n)
  }
  function stopEnableDatacenter() {
    setDatacenterSelectedToStateCange(null)
  }
  function startDeleteDatacenter(n: DataCenter) {
    setDatacenterToDelete(n)
  }
  function confirmDeleteDatacenter() {
    var x = deleteDatacenterSelected
    if (x == null) { return; }
    setIsLoading(true)
    setDatacenterToDelete(null)
    axios.delete(API_PATH + '/datacenter', { data: { id: x.id } })
      .then(function (response) {
        getDataCenters()
        setAlertElm(<Alert onClose={() => { setAlertElm(null) }}>Updaed</Alert>)
      })
      .catch(function (error) {
        console.log(error);
        getDataCenters()
        setAlertElm(<Alert onClose={() => { setAlertElm(null) }} severity="error">Failed to update</Alert>)
      });
  }
  function deleteDone() {
    setDatacenterToDelete(null)
  }
  function getIndividualDatacenterInfo(id: string) {
    axios.get('/' + API_PATH + '/datacenter/' + id + '/')
      .then(function (response) {
        // handle success
        let info = response.data
        let total = info.machineList.length
        var disabledM = 0;
        var hasError = 0;
        var hasWarning = 0;
        var snoozed = 0;
        var onRepair = 0;
        var repairRequired = 0;
        info.machineList.forEach((n: any) => {
          if (n.additionalStatus === "MACHINE_REPAIR_REQUIRED") {
            repairRequired++;
          } else if (n.repairing) {
            onRepair++;
          } else if (n.disable) {
            disabledM++;
          } else if (!(dateNow > new Date(Number(n.snoozedTill)))) {
            snoozed++;
          } else if (n.health?.hasError ?? false) {
            hasError++;
          } else if (n.health?.hasWarning) {
            hasWarning++;
          }
        })
        setDatacenterInfo(pushInfoToExistingData({ total: total, disabled: disabledM, hasError: hasError, snoozed: snoozed, hasWarning: hasWarning, id: id, onRepair: onRepair, repairRequired: repairRequired }))
      })
      .catch(function (error) {
        // handle error
      })
  }
  function pushInfoToExistingData(d: DataCenterInfo): DataCenterInfo[] {
    datacenterData.set(d.id, d)
    return Array.from(datacenterData, ([name, value]) => (value));;
  }
  function getDataCenters() {
    setIsLoading(true)
    loadDataCenter(() => { setIsLoading(false) })
  }
  function loadDataCenter(done: () => void) {
    axios.get(API_PATH + '/datacenter')
      .then(function (response) {
        // handle success
        var result: DataCenter[] = []
        response.data.forEach((n: any) => {
          result.push(createDataCenter(n.id, n.centername, n.disable, null, n.total_machines, n.engineerid, n.bot_key, n.chat_id, n.restart, n.zen_key))
          getIndividualDatacenterInfo(n.id)
        })
        setRows(result)
        done()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        done()
      })
  }
  useEffect(() => {
    getDataCenters()
    signedUser(setLoginUser)
    let idInterval = setInterval(() => { loadDataCenter(() => { }) }, 15000);
    return () => clearInterval(idInterval);
  }, [])
  return (<Grid container >
    <Grid container style={{ paddingBottom: "20px" }} >
      <Grid item xs={9}>
        <Typography variant="h4" component="h2">
          Data centers
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={() => { setDataCenterToEdit(createDataCenter("", "", false, null, null, null, null, null, false, null)) }} >Add</Button>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: 200 }}>Name</TableCell>
                <TableCell style={{ minWidth: 400 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={(row.disable) ? { background: "#eee" } : {}} >

                      <TableCell >
                        <span> {row.centername} (#{row.id}) </span>
                        <DatacenterInfoElement data={datacenterInfo.find(o => o.id === row.id) ?? null} datacenter={row} />
                      </TableCell>
                      <TableCell>
                        <Grid container >
                          <Grid item xs={3}>
                            <Button onClick={(e) => { navigate('/DataCenter/' + row.id) }} size="small" variant="outlined" >Machines</Button>
                          </Grid>
                          <Grid item xs={3}>
                            {(!row.disable) && <Button color="error" size="small" onClick={(e) => { startEnableDatacenter(row, false) }} variant="outlined" >Disable</Button>}
                            {(row.disable) && <Button color="success" size="small" onClick={(e) => { startEnableDatacenter(row, true) }} variant="outlined" >Enable</Button>}
                          </Grid>
                          <Grid item xs={3}>
                            <Button size="small" onClick={(e) => { setDataCenterToEdit(row) }} variant="outlined" ><EditIcon /></Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Button color="error" onClick={(e) => { startDeleteDatacenter(row) }} size="small" variant="outlined" ><DeleteIcon /></Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
    <Modal
      open={deleteDatacenterSelected != null}
      onClose={deleteDone}
      aria-labelledby="Delete"
      aria-describedby="Delete"
    >
      <Box sx={Modalstyle}>
        <Typography variant="h6" component="h2">
          Delete : {deleteDatacenterSelected?.centername} (#{deleteDatacenterSelected?.id})
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Please confirm deleting datacenter
        </Typography>
        <Grid container style={{ paddingTop: "20px" }}>
          <Grid item xs={6}><Button onClick={deleteDone} size="small" variant="contained" >Cancel</Button></Grid>
          <Grid item xs={6}><Button color="error" onClick={confirmDeleteDatacenter} size="small" variant="contained" style={{ float: "right" }} >Delete</Button></Grid>
        </Grid>
      </Box>
    </Modal>

    <Modal
      open={datacenterSelectedToStateCange != null}
      onClose={stopEnableDatacenter}
      aria-labelledby="Delete"
      aria-describedby="Delete"
    >
      <Box sx={Modalstyle}>
        <Typography variant="h6" component="h2">
          {(datacenterSelectedToStateCange?.disable) ? "Enable" : "Disable"} : {datacenterSelectedToStateCange?.centername} (#{datacenterSelectedToStateCange?.id})
        </Typography>
        <Typography sx={{ mt: 2 }}>
          Please confirm {(datacenterSelectedToStateCange?.disable) ? "Enable" : "Disable"} datacenter
        </Typography>
        <Grid container style={{ paddingTop: "20px" }}>
          <Grid item xs={6}><Button onClick={stopEnableDatacenter} size="small" variant="contained" >Cancel</Button></Grid>
          <Grid item xs={6}><Button color="error" onClick={confirmDatacenterStatusChange} size="small" variant="contained" style={{ float: "right" }} >{(datacenterSelectedToStateCange?.disable) ? "Enable" : "Disable"}</Button></Grid>
        </Grid>
      </Box>
    </Modal>
    {(dataCenterToEdit != null) && (<DataCenterEdit
      updateDone={() => { getDataCenters(); setDataCenterToEdit(null); }}
      updateCancelled={function (): void {
        setDataCenterToEdit(null)
      }}
      data={dataCenterToEdit} />)}

    <Snackbar open={alertElm != null} autoHideDuration={3000} onClose={() => { setAlertElm(null) }}>
      {alertElm}
    </Snackbar>

    {(isLoading) && (<LoadingScreen />)}

  </Grid >)
}

export default DataCenters;
