import React, { useEffect, useState } from "react";
import { Button, TextField, InputAdornment, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../../App";
import { Machine, timeElapsedSince } from "../../SubPages/DataCenterDetails";

function MachineRepair(props: { onChange: () => void, onCancel: () => void, data: Machine, isRequestToRepair?: boolean }) {
  const { onChange, onCancel, data } = props
  const [message, setMessage] = React.useState("");
  const [alertElm, setAlertElm] = React.useState<any | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  function updteMachine() {
    if (message.trim() == "") {
      setAlertElm(<Alert severity="error">Message can't be empty</Alert>)
      return
    }
    setAlertElm(null)

    setIsProcessing(true)
    axios.post('/' + API_PATH + ((props.isRequestToRepair) ? "/machine/needsRepair" : "/machine/repair"), null, { params: { macId: data.macId, reason: message } })
      .then(function (response) {
        setIsProcessing(false)
        onChange()
      })
      .catch(function (error) {
        setIsProcessing(false)
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(data)
  }, [])
  return (<Dialog open={true} onClose={() => { onCancel() }} fullWidth maxWidth="sm" >
    {(!data.isSnoozed) && (<>
      <DialogTitle style={{ textAlign: "center" }}>
        {(props.isRequestToRepair) ? "Mark Machine Needed Repair" : (data.repairing) ? "Confirm Repair completed" : "Confirm Sent Machine for Repair "}
        <br /> #{data.id} {data.workerId}</DialogTitle>
      <DialogContent>
        {("MACHINE_REPAIR_REQUIRED" === data.additionalStatus) && (<p>Machine already Marked to repair {timeElapsedSince(data.additionalStatusTime ?? 0)} ago</p>)}
        <TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          defaultValue={message}
          style={{ marginTop: "5px" }}
          onChange={(e) => { setMessage(e.target.value) }}
        />
      </DialogContent>
    </>)}
    <DialogActions>
      <Grid container>
        <Grid item xs={12}>
          {(alertElm != null) && (alertElm)}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={isProcessing} onClick={() => { onCancel() }}>Cancel</Button>
          <Button disabled={isProcessing} onClick={() => { updteMachine(); }} color="secondary">
            {(props.isRequestToRepair) ? "Needed Repair" : (data.repairing) ? "Repair completed" : "Sent to Repair"}
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>);
}

export default MachineRepair;