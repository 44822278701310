import { Box, Button, Card, Chip, Divider, Grid, Modal, Stack, Typography } from "@mui/material";
import { appContext } from "../../../App";
import { useContext, useState } from "react";

import SettingsPool from "./Pool/SettingsPool";
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -0%)',
  width: "80%",
  maxHeight: "80%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'scroll',
  p: 4,
};

function TileMachinePool() {
  const page = useContext(appContext);

  const [loadPoolManageModal, setLoadPoolManageModal] = useState<boolean>(false);

  return (<Grid item xs={12} sm={6} md={4} lg={3}>
    <Card variant="outlined" style={{ margin: "5px" }} >
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom variant="h5" component="div">
            Machine Pool
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            <AutoAwesomeMotionIcon />
          </Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          Mchine pool available
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }} style={{ textAlign: "right" }}>
        <Button variant="contained" onClick={(e) => { setLoadPoolManageModal(true) }} >Manage</Button>
      </Box>
      {(loadPoolManageModal) && (<Modal
        open={loadPoolManageModal}
        onClose={() => { setLoadPoolManageModal(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <SettingsPool onclose={() => { setLoadPoolManageModal(false) }} />
        </Box>
      </Modal>)}
    </Card>
  </Grid>)
}

export default TileMachinePool;

