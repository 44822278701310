import { Machine } from "../../SubPages/DataCenterDetails";
import { DataCenter } from "../../SubPages/DataCenters";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";


function DownloadResults(props: { onClose: () => void, machines: Machine[] | null, datacenters: Map<string, DataCenter> }) {
    const [dataUrl, setDataUrl] = useState("")
    function convertArrayOfObjectsToCSV(array: Object[]): string {
        const header = Object.keys(array[0]).join(',');
        const rows = array.map(obj => Object.values(obj).join(',')).join('\n');
        return header + '\n' + rows;
    }
    function getResults(): Object[] {
        var result: Object[] = props.machines?.map(n => {
            var o = new Map<string, string>();
            o.set('Worker Id', n.workerId);
            o.set('Datacenter', props.datacenters.get(n.centerId)?.centername ?? "");
            o.set('User Group', n.userGroup?.name ?? "");
            o.set('MAC', n.macId);
            o.set('Model', n.model);
            o.set('SN', n.serialNo);
            o.set('Tarrif', n.hostingCost ?? "");
            o.set('Status', (n.disable) ? "Disabled" : (n.repairing) ? "Repairing" : "Enabled");
            o.set('Additional Status', n.additionalStatus ?? "");
            o.set('Consumption', n.machineConfig?.power ?? "")
            return Object.fromEntries(o);
        }) ?? [];
        return result;
    }
    function generateDownloadLink() {
        const csv = convertArrayOfObjectsToCSV(getResults());
        const blob = new Blob([csv], { type: 'text/csv' });
        setDataUrl(window.URL.createObjectURL(blob));
    }
    useEffect(() => {
        generateDownloadLink()
    }, [])
    function onDownload() {
        const link = document.createElement("a");
        link.download = 'results.csv';
        link.href = dataUrl;
        link.click();
    }
    return <>
        <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm" >
            <DialogTitle style={{ textAlign: "center" }} >Download result</DialogTitle>
            <DialogContent>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Button onClick={onDownload} variant="contained" color="primary">
                        Download
                    </Button>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}  >Cancel</Button>
            </DialogActions>
        </Dialog>
    </>

}

export default DownloadResults;

