import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import LoadingScreen from "../other/LoadingScreen";
import { API_PATH } from "../../App";
import { DataCenter, createDataCenter } from "./DataCenters";
import { Machine, MachineConfig, createHealth, createMachineConfig, createMachineFromObject, timeElapsed } from "./DataCenterDetails";
import MachineInfoRow from "../other/MachineInfoRow";

import { useSearchParams } from 'react-router-dom';
import AddToUserGroupModal from "../other/AddToUserGroupModal";
import { UserGroup } from "../other/settings/UserGroup/SettingsUserGroup";
import MachineUpdateHostingCharge from "../other/machine/MachineUpdateHostingCharge";
import { SORT_ORDER, sortMachineData, sortResultData } from "../../Helpers/MachineHelper";
import DownloadResults from "../other/search/DownloadResults";
import { AuthUser } from "./Users";


function SearchPage(props: { user: AuthUser | null }) {

  const [searchAction, setSearchAction] = useState<any | null>(null)

  const [searchKey, setSearchKey] = useState("")
  const [selectedMachineStateType, setSelectedMachineStateType] = useState<string>("")
  const [selectedDatacenters, setSelectedDatacenters] = useState<string>("")
  const [selectedUserGroups, setSelectedUserGroups] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)
  const [isResultLoading, setIsResultLoading] = useState(false)
  const [searchResults, setSearchResult] = useState<Machine[] | null>(null)
  const [filteredResults, setFilteredResult] = useState<Machine[] | null>(null)
  const [datacenters, setDatacenters] = useState<Map<string, DataCenter>>(new Map())
  const [disabledDatacenters, setDisabledDatacenters] = useState<Map<string, boolean>>(new Map())
  const [userGroups, setUserGroups] = useState<UserGroup[]>([])
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortOrder, setSortOrder] = useState(SORT_ORDER.NONE)

  const [showDownload, setShowDownload] = useState(false);
  const [showAddToUserGroup, setShowAddToUserGroup] = useState(false);
  const [showUpdateHostingCharge, setShowUpdateHostingCharge] = useState(false);

  const [selectedMachines, setSelectedMachines] = useState(new Set<string>())

  const [selectAllSearchResults, setSelectAllSearchResults] = useState(false);

  const [errorMessage, setErrorMessage] = useState("")

  const [machineConfigs, setMachineConfigs] = React.useState<MachineConfig[]>([]);

  const [resultHideDisabled, setResultHideDisabled] = useState(false)
  const [resultHideSnoozed, setResultHideSnoozed] = useState(false)
  const [resultHideOnRepair, setResultHideOnRepair] = useState(false)
  const [resultHideRepairRequired, setResultHideRepairRequired] = useState(false)
  const [resultHideHasError, setResultHideHasError] = useState(false)
  const [resultHideHighTemparature, setResultHideHighTemparature] = useState(false)
  const [resultHideWarnings, setResultHideWarning] = useState(false)
  const [resultHideDisabledDatacenters, setResultHideDisabledDatacenters] = useState(false)
  const [resultHideFirmwareIssue, setResultHideFirmwareIssue] = useState(true)


  const [resultFilters, setResultFilters] = useState(new Set<string>())
  const [filterAction, setFilterAction] = useState<any>(null)

  function searchData() {
    let params = new URLSearchParams();
    params.append("q", searchKey);
    params.append("stateType", selectedMachineStateType);
    params.append("datacenters", selectedDatacenters);
    params.append("userGroups", selectedUserGroups);
    params.append("resultFilters", Array.from(resultFilters).join(","));
    setSearchParams(params)
    searchDataApi(searchKey, selectedMachineStateType, selectedDatacenters, selectedUserGroups);
  }

  function searchDataApi(key: string, selectedMachineStateType: string, selectedDatacenters: string, selectedUserGroups: string) {
    setIsResultLoading(true)
    setFilteredResult([])
    setSelectedMachines(new Set())
    axios.get(API_PATH + '/machine/search_machine', {
      params:
      {
        key: key,
        stateType: selectedMachineStateType,
        datacenters: selectedDatacenters,
        userGroups: selectedUserGroups
      }
    })
      .then(function (response) {
        var result: Machine[] = []
        let dateNow = new Date();
        response.data.forEach((n: any) => {
          result.push(createMachineFromObject(n, dateNow))
        });
        setSearchResult(sortMachineData(result))
      })
      .catch(function (error) {
        // handle error
        setIsResultLoading(false)
      })
  }

  const SearchButton = () => (
    <IconButton onClick={searchData}>
      <SearchIcon />
    </IconButton>
  )

  function getData() {
    setIsLoading(true)
    axios.get(API_PATH + '/datacenter')
      .then(function (response) {
        var result: Map<string, DataCenter> = new Map()
        var resDisabledDatacenter: Map<string, boolean> = new Map()
        response.data.forEach((n: any) => {
          var dc = createDataCenter(n.id, n.centername, n.disable, null, n.total_machines, n.engineerid, n.bot_key, n.chat_id, n.restart, n.zen_key);
          result.set(n.id, dc)
          if (dc.disable) {
            resDisabledDatacenter.set(dc.id, true)
          }
        })
        setDatacenters(result)
        setDisabledDatacenters(resDisabledDatacenter)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false)
      })
    axios.get('/' + API_PATH + '/usergroup')
      .then(function (response) {
        var res: UserGroup[] = []
        response.data.forEach((n: any) => {
          res.push(n)
        });
        setUserGroups(res)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  function processAllSelection(selection: boolean) {
    var tmpSelectedMachine = new Set(selectedMachines)
    if (selection && (searchResults)) {
      for (const [key, value] of Object.entries(searchResults)) {
        tmpSelectedMachine.add(value.macId)
      }
    } else {
      tmpSelectedMachine.clear()
    }
    setSelectedMachines(tmpSelectedMachine)
    setSelectAllSearchResults(selection)
  }

  function proceswsSelection(macId: string, selection: boolean) {
    var clonedSet = new Set(selectedMachines);
    if (selection) {
      clonedSet.add(macId);
    } else {
      clonedSet.delete(macId);
    }
    setSelectedMachines(clonedSet)
  }

  function onAddedToUserGroup(ug: UserGroup | null) {
    if (ug == null) {
      setErrorMessage("User Group can't be empty!")
      return;
    }
    if (selectedMachines.size == 0) {
      setErrorMessage("Need to select atleast one machine!")
      return;
    }
    setIsLoading(true);
    axios.post('/' + API_PATH + "/machine/add_to_group",
      {
        "macIds": Array.from(selectedMachines),
        "userGroupId": ug.id
      })
      .then((response) => {
        searchData()
        setShowAddToUserGroup(false)
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorMessage("Error saving data!")
      });
  }
  const handleMachineStateSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;
    setSelectedMachineStateType(
      typeof value === 'string' ? value : value.toString(),
    );
  };
  const handleDatacenterSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;
    var selection = typeof value === 'string' ? value : value.toString()
    setSelectedDatacenters(selection)
  };
  const handleUserGroupSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;
    var selection = typeof value === 'string' ? value : value.toString()
    setSelectedUserGroups(selection)
  };

  const handleSortChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value }, } = event;
    var selection = typeof value === 'string' ? value : value.toString()
    setSortOrder(selection)
  };
  function readParams() {
    var loadData = false;
    let key = searchParams.get("q");
    if (key != null && key.trim().length > 0) {
      setSearchKey(key.trim())
      loadData = true;
    }
    let datacenters = searchParams.get("datacenters");
    if (datacenters != null && datacenters.trim().length > 0) {
      setSelectedDatacenters(datacenters.trim())
      loadData = true;
    }
    let userGroups = searchParams.get("userGroups");
    if (userGroups != null && userGroups.trim().length > 0) {
      setSelectedUserGroups(userGroups.trim())
      loadData = true;
    }
    let stateType = searchParams.get("stateType");
    if (stateType != null && stateType.trim().length > 0) {
      setSelectedMachineStateType(stateType.trim())
      loadData = true;
    }
    processFilterParms(searchParams.get("resultFilters"))
    if (loadData) {
      setSearchAction(true)
    }
  }
  function getMachineConfigs() {
    axios.get('/' + API_PATH + '/system/scanconfig')
      .then(function (response) {
        // handle success
        processMachineConfig(response.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  function processMachineConfig(data: any) {
    var newMachineConfigs: MachineConfig[] = []
    data.forEach((n: any) => {
      newMachineConfigs.push(createMachineConfig(n))
    });
    setMachineConfigs(newMachineConfigs);
  }
  function processFilterParms(resultFilters: string | null) {
    if (resultFilters == null) { return; }
    let filters = new Set(resultFilters.split(","))
    setResultFilters(filters)
    if (filters.has("hideDisabled")) {
      setResultHideDisabled(true);
    }
    if (filters.has("hideSnoozed")) {
      setResultHideSnoozed(true);
    }
    if (filters.has("hideOnRepair")) {
      setResultHideOnRepair(true);
    }
    if (filters.has("hideRepairRequired")) {
      setResultHideRepairRequired(true);
    }
    if (filters.has("hideHasError")) {
      setResultHideHasError(true);
    }
    if (filters.has("hideHighTemparature")) {
      setResultHideHighTemparature(true);
    }
    if (filters.has("hideWarnings")) {
      setResultHideWarning(true);
    }
    if (filters.has("hideDisabledDatacenters")) {
      setResultHideDisabledDatacenters(true);
    }
  }

  function updateFilter() {
    setFilterAction(Math.random())
  }

  function applyFilters() {
    setResultFilters(
      (new Set<string>())
        .add(resultHideDisabled ? "hideDisabled" : "")
        .add(resultHideSnoozed ? "hideSnoozed" : "")
        .add(resultHideOnRepair ? "hideOnRepair" : "")
        .add(resultHideRepairRequired ? "hideRepairRequired" : "")
        .add(resultHideHasError ? "hideHasError" : "")
        .add(resultHideHighTemparature ? "hideHighTemparature" : "")
        .add(resultHideWarnings ? "hideWarnings" : "")
        .add(resultHideDisabledDatacenters ? "hideDisabledDatacenters" : "")
        .add(resultHideFirmwareIssue ? "hideFirmwareIssue" : "")
    );
    setFilteredResult(sortResultData(searchResults?.filter((n) => {
      return (!resultHideDisabled || !n.disable) &&
        (!resultHideSnoozed || !n.isSnoozed) &&
        (!resultHideOnRepair || !n.repairing) &&
        (!resultHideRepairRequired || !(n.additionalStatus === "MACHINE_REPAIR_REQUIRED")) &&
        (!resultHideHasError || !n.health?.hasError) &&
        (!resultHideWarnings || !n.health?.hasWarning) &&
        (!resultHideHighTemparature || ((n.health?.errorDetails ?? n.health?.message ?? "").indexOf("High temperature") == -1)) &&
        (!resultHideFirmwareIssue || !(((n.health?.errorDetails ?? n.health?.message ?? "").indexOf("Firmware issue") != -1) || (n.health?.errorDetails ?? n.health?.message ?? "").indexOf("Stats data is empty.") != -1)) &&
        (!resultHideDisabledDatacenters || !disabledDatacenters.has(n.centerId))
    }) ?? [], sortOrder))
  }
  useEffect(() => {
    getData()
    readParams()
    getMachineConfigs()
  }, [])

  useEffect(() => {
    if (searchAction != null) {
      searchData()
      setSearchAction(null)
    }
  }, [searchAction])

  useEffect(() => {
    applyFilters()
    setIsResultLoading(false)
  }, [filterAction, searchResults, sortOrder])

  useEffect(() => {
    let params = new URLSearchParams(searchParams);
    params.set("resultFilters", Array.from(resultFilters).join(","));
    setSearchParams(params)
  }, [resultFilters])

  return (<Grid container >
    {(isLoading || isResultLoading) && <LoadingScreen />}
    {(!isLoading) &&
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={12}  >
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            <Grid item xs={12} sm={6}  >
              <TextField fullWidth label="Search key" variant="outlined"
                value={searchKey}
                onChange={e => { setSearchKey(e.target.value) }}
                onKeyUp={e => { if (e.key == "Enter") { searchData() } }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth >
                <InputLabel id="serch-type">Search on</InputLabel>
                <Select
                  labelId="serch-type"
                  value={[selectedMachineStateType]}
                  onChange={handleMachineStateSelectionChange}
                  fullWidth
                  label="Search on"
                >
                  <MenuItem value="">Any</MenuItem>
                  <MenuItem value="Snoozed">Snoozed</MenuItem>
                  <MenuItem value="Disabled">Disabled</MenuItem>
                  <MenuItem value="warning">Has Warning</MenuItem>
                  <MenuItem value="error">Has Error</MenuItem>
                  <MenuItem value="needRepair">Repair Needed</MenuItem>
                  <MenuItem value="repair">On Repair</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth >
                <InputLabel id="serch-dc">Datacenter</InputLabel>
                <Select
                  labelId="serch-dc"
                  value={(datacenters.size == 0) ? [] : [selectedDatacenters]}
                  onChange={handleDatacenterSelectionChange}
                  fullWidth
                  label="Search on"
                >
                  <MenuItem value="">All</MenuItem>
                  {(Array.from(datacenters).map((n, k) => <MenuItem key={k} value={n[1].id}>{n[1].centername}</MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {((props.user?.authRole ?? "") != "CLIENT") && (

                <FormControl fullWidth >
                  <InputLabel id="serch-ug">User Group</InputLabel>
                  <Select
                    labelId="serch-ug"
                    value={(userGroups.length == 0) ? [] : [selectedUserGroups]}
                    onChange={handleUserGroupSelectionChange}
                    fullWidth
                    label="Search on"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="-1 ">No UserGroup</MenuItem>
                    {(userGroups.map((n, k) => <MenuItem key={k} value={n.id}>{n.name}</MenuItem>))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sm={2}  >
                  <Button variant="contained" fullWidth size="large" onClick={searchData} style={{ padding: "13px 22px" }} > Search </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container justifyContent="flex-end">
                {((filteredResults?.length ?? 0) > 0) && (<Grid item xs={6} sm={4} >
                  <FormControl fullWidth >
                    <InputLabel id="sort-ug" size="small">Sort</InputLabel>
                    <Select
                      labelId="sort-ug"
                      value={(sortOrder.length == 0) ? [] : [sortOrder]}
                      onChange={handleSortChange}
                      fullWidth
                      size="small"
                      label="Sort on"
                    >
                      <MenuItem value={SORT_ORDER.NONE}>None</MenuItem>
                      <MenuItem value={SORT_ORDER.WID_DESC}>Worker ID( Decending )</MenuItem>
                      <MenuItem value={SORT_ORDER.WID_ASC}>Worker ID( Ascending )</MenuItem>
                      <MenuItem value={SORT_ORDER.MODEL}>Model</MenuItem>
                      <MenuItem value={SORT_ORDER.DATACENTER}>Datacenter</MenuItem>
                      <MenuItem value={SORT_ORDER.USER_GROUP_DESC}>User Group( Decending )</MenuItem>
                      <MenuItem value={SORT_ORDER.USER_GROUP_ASC}>User Group( Ascending )</MenuItem>
                      <MenuItem value={SORT_ORDER.LU_DESC}>Last Update( Decending )</MenuItem>
                      <MenuItem value={SORT_ORDER.LU_ASC}>Last Update( Ascending )</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {(filteredResults != null) &&
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <span>Hide : </span>
                    <FormControlLabel
                      label="Disabled"
                      control={<Checkbox checked={resultHideDisabled} onChange={e => { setResultHideDisabled(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="Snoozed"
                      control={<Checkbox checked={resultHideSnoozed} onChange={e => { setResultHideSnoozed(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="On Repair"
                      control={<Checkbox checked={resultHideOnRepair} onChange={e => { setResultHideOnRepair(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="Repair Needed"
                      control={<Checkbox checked={resultHideRepairRequired} onChange={e => { setResultHideRepairRequired(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="Has Error"
                      control={<Checkbox checked={resultHideHasError} onChange={e => { setResultHideHasError(e.target.checked); updateFilter() }} />}
                    />
                    <FormControlLabel
                      label="High Temparature Error"
                      control={<Checkbox checked={resultHideHighTemparature} onChange={e => { setResultHideHighTemparature(e.target.checked); updateFilter() }} />}
                    />
                    <FormControlLabel
                      label="Has Warnings"
                      control={<Checkbox checked={resultHideWarnings} onChange={e => { setResultHideWarning(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="Disabled Datacenter"
                      control={<Checkbox checked={resultHideDisabledDatacenters} onChange={e => { setResultHideDisabledDatacenters(e.target.checked); updateFilter(); }} />}
                    />
                    <FormControlLabel
                      label="Firmware issue"
                      control={<Checkbox checked={resultHideFirmwareIssue} onChange={e => { setResultHideFirmwareIssue(e.target.checked); updateFilter(); }} />}
                    />

                  </Grid>
                </Grid>
                <Grid container>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12} sm={6} style={{ paddingBottom: "15px" }} >
                      <small><b>{filteredResults.length} Results. </b> </small>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ textAlign: "right", paddingBottom: "15px" }} >
                      <Button variant="contained" disabled={selectedMachines.size == 0} size="small" onClick={e => { setShowUpdateHostingCharge(true) }} > Update Hosting Charge</Button> &nbsp;
                      <Button variant="contained" disabled={selectedMachines.size == 0} size="small" onClick={e => { setShowAddToUserGroup(true) }} > Add to User Group </Button> &nbsp;
                      <Button variant="contained" disabled={filteredResults.length == 0} size="small" onClick={e => { setShowDownload(true) }} > Download </Button>
                    </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ padding: "0px 0px 0px 4px" }}><Checkbox value={"all"} checked={selectAllSearchResults} onChange={e => { processAllSelection(e.target.checked) }} /></TableCell>
                          <TableCell>Worker id</TableCell>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell>Model / SN / MAC / Url</TableCell>
                          <TableCell>Hosting charge </TableCell>
                          <TableCell>User Group / Datacenter </TableCell>
                          {((props.user?.authRole ?? "") != "CLIENT") && (<TableCell>Settings</TableCell>)}

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredResults.map((row) => (
                          <MachineInfoRow data={row} key={row.id}
                            firstElement={<Checkbox value={row.macId}
                              checked={selectedMachines.has(row.macId)}
                              onChange={e => { proceswsSelection(row.macId, e.target.checked); }} />}
                            datacenterName={datacenters.get(row.centerId)?.centername}
                            refresh={() => { searchData(); }} machineConfigs={machineConfigs}
                            showHistory={((props.user?.authRole ?? "") != "CLIENT")}
                            showSettings={((props.user?.authRole ?? "") != "CLIENT")} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            }
          </Grid>
          {(showAddToUserGroup) && (<AddToUserGroupModal show={showAddToUserGroup} isloading={isLoading} onSubmit={onAddedToUserGroup} onClose={() => { setShowAddToUserGroup(false); searchData(); }} />)}
          {(showUpdateHostingCharge) && (<MachineUpdateHostingCharge macIds={Array.from(selectedMachines)} onClose={() => { setShowUpdateHostingCharge(false) }} afterUpdate={() => { setShowUpdateHostingCharge(false); searchData(); }} />)}
          {(showDownload) && (<DownloadResults machines={filteredResults} datacenters={datacenters} onClose={() => { setShowDownload(false) }} />)}
        </Grid>
      </Grid>
    }
    <Snackbar
      open={errorMessage.length > 0}
      autoHideDuration={2000}
      onClose={() => { setErrorMessage("") }}
      message={errorMessage}
      color="error"
    />
  </Grid >)
}

export default SearchPage;
