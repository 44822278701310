
import React, { useEffect, useState } from "react";
import { DataCenter } from "../SubPages/DataCenters";
import { Modal, Box, Typography, Grid, Button, TextField, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, FormControlLabel, FormLabel, Radio, RadioGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../App";
import { FBuser } from "../../Helpers/Auth";
import { Engineer, createEngineer } from "../SubPages/Engineers";

const Modalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #444',
  width: 400,
  boxShadow: 24,
  p: 4,
};

function DataCenterEdit(props: { updateDone: () => void, updateCancelled: () => void, data: DataCenter }) {
  const { updateDone, updateCancelled, data } = props


  const [currentDatacenter, setCurrentDatacenter] = useState<DataCenter | null>(data);

  const [datacenter, setDatacenter] = React.useState(data.centername);
  const [disabled, setDisabled] = React.useState(data.disable);
  const [selEngineer, setSelEngineer] = React.useState<string>(data.engineerid ?? "");
  const [sheduleRestart, setSheduleRestart] = React.useState<boolean>(data.restart ?? false);


  const [isEngineersLoaded, setIsEngineersLoaded] = useState(false);
  const [engineerData, setEngineerData] = useState<Engineer[]>([]);


  const [totalMachines, setTotalMachines] = useState<string | null>(data.total_machines);
  const [botKey, setBotKey] = useState<string | null>(data.bot_key);
  const [chatId, setChatId] = useState<string | null>(data.chat_id);
  const [zenDutyKey,setZenDutyKey] = useState<string | null>(data.zen_key);




  function getEngineers() {
    setIsEngineersLoaded(false)
    axios.get(API_PATH + '/engineers')
      .then(function (response) {
        // handle success
        var result: Engineer[] = []
        response.data.forEach((n: any) => {
          result.push(createEngineer(n.eid, n.name, n.email, n.phone, n.whatsapp, n.botim_id, null))
        })
        setEngineerData(result)
        setIsEngineersLoaded(true)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsEngineersLoaded(true)
      })
  }

  function save(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (data.id == "") {
      axios.post(API_PATH + '/datacenter', { disable: disabled, centername: datacenter, engineerid: selEngineer, chat_id: chatId, bot_key: botKey, total_machines: totalMachines, restart: sheduleRestart, zen_key: zenDutyKey })
        .then(function (response) {
          updateDone()
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios.put(API_PATH + '/datacenter', { id: data.id, disable: disabled, centername: datacenter, engineerid: selEngineer, chat_id: chatId, bot_key: botKey, total_machines: totalMachines, restart: sheduleRestart, zen_key: zenDutyKey })
        .then(function (response) {
          updateDone()
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    getEngineers()
  }, [])

  return (
    <Dialog
      open={currentDatacenter != null}
      onClose={updateCancelled}
      PaperProps={{
        component: 'form',
        onSubmit: save,
      }}
      fullWidth={true}
      maxWidth="sm" >
      <DialogTitle> {(data.id == "") ? "Create Datacenter" : "Update Datacenter #" + data.id + " " + data.centername}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TextField label="Datacenter Name" variant="outlined" value={datacenter} onChange={(e) => { setDatacenter(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
        </Grid>
        {/* <Grid item xs={12} style={{ marginTop: "10px" }}>
          <FormControl>
            <FormLabel id="disable-group-label" >Disabled</FormLabel>
            <RadioGroup
              row
              aria-labelledby="disable-group-label"
              name="disable-group"
              value={disabled}
              onChange={(e) => { setDisabled(e.target.value as unknown as boolean) }}
            >
              <FormControlLabel value={true} control={<Radio />} label="True" />
              <FormControlLabel value={false} control={<Radio />} label="False" />
            </RadioGroup>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <TextField
            id="outlined-select-currency"
            select
            label="Engineer in Charge"
            defaultValue={data.engineerid ?? ""}
            fullWidth
            onChange={(e) => { setSelEngineer(e.target.value) }}
          >
            <MenuItem value=""> None </MenuItem>
            {engineerData.map((n) => (
              <MenuItem key={n.eid} value={n.eid}> {n.name} </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField label="Total Machines" variant="outlined" fullWidth value={totalMachines ?? ""} onChange={(e) => { setTotalMachines(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
        </Grid>


        <Grid item xs={12}>
          <TextField label="Telegram Bot Key" variant="outlined" fullWidth value={botKey ?? ""} onChange={(e) => { setBotKey(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
        </Grid>


        <Grid item xs={12}>
          <TextField label="Telegram Chat Id" variant="outlined" fullWidth value={chatId ?? ""} onChange={(e) => { setChatId(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
        </Grid>

        <Grid item xs={12}>
          <TextField label="Zen duty Key" variant="outlined" fullWidth value={zenDutyKey ?? ""} onChange={(e) => { setZenDutyKey(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
        </Grid>

        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <FormControl>
            <FormLabel id="restart-group-label" >Restart Machines </FormLabel>
            <RadioGroup
              row
              aria-labelledby="restart-group-label"
              name="restart-group"
              defaultValue={data.restart ?? false}
              onChange={(e) => { setSheduleRestart(e.target.value as unknown as boolean) }}
            >
              <FormControlLabel value={true} control={<Radio />} label="YES" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={updateCancelled}>Cancel</Button>
        <Button type="submit">Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DataCenterEdit;
