import React, { useEffect, useState } from "react";
import { Button, TextField, InputAdornment, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert, List, ListItem, ListItemText } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../../App";
import { Machine, timeElapsedSince } from "../../SubPages/DataCenterDetails";

function MachineRestart(props: { onChange: () => void, onCancel: () => void, data: Machine }) {
  const { onChange, onCancel, data } = props
  const [message, setMessage] = React.useState("");
  const [alertElm, setAlertElm] = React.useState<any | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [taskKey, setTaskKey] = useState<string | null>(null);
  const [taskStatus, setTaskStatus] = useState<string[]>([]);
  const [taskStartLooking, setTaskStartLooking] = useState<string | null>(null);

  var taskStatusFlag = { prev: "", counter: 0 };

  function updteMachine() {
    if (message.trim() == "") {
      setAlertElm(<Alert severity="error">Message can't be empty</Alert>)
      return
    }
    setAlertElm(null)
    setTaskStartLooking(null)
    setIsProcessing(true)
    axios.post('/' + API_PATH + "/task/start", {
      "taskId": 1,
      "machine": props.data,
      "data": [],
      "reason": message
    })
      .then(function (response) {
        checkTaskStatus(response.data.taskKey)
      })
      .catch(function (error) {
        setIsProcessing(false)
        console.log(error);
      });
  }

  function checkTaskStatus(key: string) {
    console.log("watching task : " + key)
    setTaskStartLooking(key)
    setTaskKey(key)
    setTaskStatus([])
    console.log(" task interval : " + key)
    axios.get('/' + API_PATH + '/task/taskcheck/' + key + '')
      .then(processTaskStatus)
  }

  function processTaskStatus(response: any) {
    if ((response != null) && (response.data != null))
      switch (response.data.status.trim().toUpperCase()) {
        case "TASK_ADDED_TO_QUEUE": pushToTaskStatus("Task added to queue..."); break;
        case "TASK_PROCESSING": pushToTaskStatus("Task under processing..."); break;

        case "TASK_COMPLETED":
          pushToTaskStatus("Task completed.");
          setIsProcessing(false);
          setTaskStartLooking(null)
          break;

        case "TASK_FAILED":
        case "TASK_TIME_OUT":
        case "TASK_NOT_FOUND":
          pushToTaskStatus("Error : " + response.data.status);
          setIsProcessing(false);
          setTaskStartLooking(null)
          break;
      }
  }
  function pushToTaskStatus(status: string) {
    console.log(taskStatusFlag)
    var taskResultArray = Array.from(taskStatus)
    if (taskStatusFlag.prev === status) {
      var counter = Number(taskStatusFlag.counter)
      taskStatusFlag = { prev: status, counter: (counter + 1) }
      taskResultArray[(taskResultArray.length == 0) ? 0 : taskResultArray.length - 1] = status + " [" + (counter + 1) + "]"
    } else {
      taskStatusFlag = { prev: status, counter: 0 }
      taskResultArray.push(status)
    }
    console.log(taskResultArray)
    setTaskStatus(taskResultArray)
  }

  function onClose() {
    setTaskKey(null)
    onChange()
  }

  useEffect(() => {
    let idInterval = setInterval(() => {
      if (taskStartLooking) {
        checkTaskStatus(taskStartLooking);
      }
    }, 1500);
    return () => clearInterval(idInterval);
  }, [taskStartLooking])
  return (<Dialog open={true} onClose={() => { onClose() }} fullWidth maxWidth="sm" >
    <>
      <DialogTitle style={{ textAlign: "center" }}>
        {(!isProcessing) ? "Confirm Restart Machine" : " Restarting Machine"}

        <br /> #{data.id} {data.workerId}</DialogTitle>
      <DialogContent>
        {(taskKey == null) && (<TextField
          label="Message"
          multiline
          rows={4}
          fullWidth
          defaultValue={message}
          style={{ marginTop: "5px" }}
          onChange={(e) => { setMessage(e.target.value) }}
        />)}

      </DialogContent>
    </>
    {(taskKey != null) && (
      <List dense={true}>
        {taskStatus.map((n, i) => <ListItem key={i}>  <ListItemText primary={n} /> </ListItem>)}
      </List>
    )}
    <DialogActions>
      <Grid container>
        <Grid item xs={12}>
          {(alertElm != null) && (alertElm)}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={() => { onClose() }}>Close</Button>
          {(taskKey == null) && (<Button disabled={isProcessing} onClick={() => { updteMachine(); }} color="secondary"> Restart </Button>)}
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>);
}

export default MachineRestart;