import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl, FormLabel, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../../App";
import { Machine, timeElapsedSince } from "../../SubPages/DataCenterDetails";

function MachineReview(props: { onChange: () => void, onCancel: () => void, data: Machine }) {
  const { onChange, onCancel, data } = props
  const [message, setMessage] = React.useState("");
  const [alertElm, setAlertElm] = React.useState<any | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState('COMMENT');

  function updteMachine() {
    if (message.trim() == "") {
      setAlertElm(<Alert severity="error">Message can't be empty</Alert>)
      return
    }
    setAlertElm(null)
    setIsProcessing(true)
    axios.post('/' + API_PATH + "/machine/review", {
      "machine": props.data,
      "message": message,
      "type": selectedType
    })
      .then(function (response) {
        setIsProcessing(false)
        onChange()
      })
      .catch(function (error) {
        setIsProcessing(false)
        console.log(error);
      });
  }
  function onClose() {
    onCancel()
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(event.target.value);
  };

  useEffect(() => {
    console.log(selectedType)
  }, [selectedType])
  return (<Dialog open={true} onClose={() => { onClose() }} fullWidth maxWidth="sm" >

    <DialogTitle style={{ textAlign: "center" }}> Review Machine </DialogTitle>
    <span style={{ padding: "0px 10px", wordBreak: "break-all", textAlign: "center" }}>#{data.id} {data.workerId}</span>
    <DialogContent>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="type-group-label"
            defaultValue={selectedType}
            name="type-group"
          >
            <FormControlLabel value="COMMENT" control={<Radio onChange={handleChange} />} label="comment" />
            <FormControlLabel value="L1_ATTENTION_NEEDED" disabled={("MACHINE_SENT_TO_REPAIR" === props.data.additionalStatus)} control={<Radio onChange={handleChange} />} label="L1 Attention needed" />
            <FormControlLabel value="REPAIR_REQUIRED" disabled={
              ("MACHINE_REPAIR_REQUIRED" === props.data.additionalStatus) ||
              ("MACHINE_SENT_TO_REPAIR" === props.data.additionalStatus)
            } control={<Radio onChange={handleChange} />} label="Repair Required" />
            <FormControlLabel value="WORKING" disabled={("MACHINE_SENT_TO_REPAIR" === props.data.additionalStatus)} control={<Radio onChange={handleChange} />} label="Working as Normal" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <TextField
        label="Note"
        multiline
        rows={4}
        fullWidth
        defaultValue={message}
        style={{ marginTop: "5px" }}
        onChange={(e) => { setMessage(e.target.value) }}
      />

    </DialogContent>
    <DialogActions>
      <Grid container>
        <Grid item xs={12}>
          {(alertElm != null) && (alertElm)}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={() => { onClose() }}>Close</Button>
          <Button disabled={isProcessing} onClick={() => { updteMachine(); }} color="secondary"> Submit </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>);
}

export default MachineReview;