import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TelegramIcon from '@mui/icons-material/Telegram';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import { signOut } from "firebase/auth";
import { auth } from './Auth';
import { useNavigate } from 'react-router-dom';
import { AuthUser } from '../Pages/SubPages/Users';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';



function MenuItems(props: { onChange: () => void, user: AuthUser | null }) {
  const navigate = useNavigate();
  const { onChange } = props
  function navigateMenu(path: string) {
    navigate(path);
  }
  function logOut(e: any) {
    e.preventDefault();
    signOut(auth).then(() => {
      // Sign-out successful.
      console.log("Signed out successfully")
      navigateMenu("/")
    }).catch((error) => {
      // An error happened.
    });
  }
  return (
    <React.Fragment>
      {(props.user?.authRole == "ADMIN") && (<>
        {/* <ListItemButton onClick={(e) => { navigateMenu("/"); onChange(); }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton> */}
        <ListItemButton onClick={(e) => { navigateMenu("/DataCenters"); onChange(); }}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Data centers" />
        </ListItemButton>
        {/* <ListItemButton  onClick={(e)=>{navigateMenu("/Machines"); onChange();}}>
      <ListItemIcon>
        <StorageIcon />
      </ListItemIcon>
      <ListItemText primary="Machines" />
    </ListItemButton> */}
        <ListItemButton onClick={(e) => { navigateMenu("/Search"); onChange(); }}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItemButton>
        <ListItemButton onClick={(e) => { navigateMenu("/Users"); onChange(); }}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
        <ListItemButton onClick={(e) => { navigateMenu("/Engineers"); onChange(); }}>
          <ListItemIcon>
            <EngineeringIcon />
          </ListItemIcon>
          <ListItemText primary="Engineers" />
        </ListItemButton>
        <ListItemButton onClick={(e) => { navigateMenu("/Telegram"); onChange(); }}>
          <ListItemIcon>
            <TelegramIcon />
          </ListItemIcon>
          <ListItemText primary="Telegram" />
        </ListItemButton>
        <ListItemButton onClick={(e) => { navigateMenu("/Settings"); onChange(); }}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
        <ListItemButton onClick={(e) => { navigateMenu("/Health"); onChange(); }}>
          <ListItemIcon>
            <MonitorHeartIcon/>
          </ListItemIcon>
          <ListItemText primary="Health" />
        </ListItemButton>
      </>)}

      {(props.user?.authRole == "CLIENT") && (<>
        <ListItemButton onClick={(e) => { navigateMenu("/Search"); onChange(); }}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItemButton>
      </>)}

      <ListItemButton onClick={logOut} >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </React.Fragment>
  )
}

export default MenuItems;
