import { CssBaseline, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../../../../App";
import LoadingScreen from "../../LoadingScreen";
import MachineBillingResult from "./MachineBillingResult";
import { useSearchParams } from "react-router-dom";
import { BillingShortDetail } from "./MachineBillingInfo";
import React from "react";

function MachineBillingShared() {
    const [isProcessingData, setIsProcessingData] = useState<boolean>(true);
    const [uniqId, setUniqId] = useState("");
    const [dataReceived, setDataReceived] = useState<BillingShortDetail[] | null>(null);
    const [info, setInfo] = useState<any | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    function getInfo() {
        setIsProcessingData(true);
        setDataReceived(null);
        var id = searchParams.get("id")
        if (id) {
            setUniqId(id);
            axios.get('/' + API_PATH + '/public/billing?id=' + id)
                .then(function (response) {
                    var res: BillingShortDetail[] = []
                    response.data.result.forEach((n: any) => {
                        res.push(n)
                    });
                    setDataReceived(res);
                    setInfo(response.data.info)
                    setIsProcessingData(false)
                })
                .catch(function (error) {
                    console.log(error);
                    setDataReceived(null)
                    setIsProcessingData(false)
                })
        }
    }

    useEffect(() => {
        getInfo()
    }, [])

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container style={{ padding: "10px" }}
                alignItems="center"
                justifyContent="center"  >
                <Grid item xs={12} style={{ maxWidth: "1100px" }}   >
                    <Grid container style={{ textAlign: "center" }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom> {info?.userGroup?.name}  </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                {info?.start?.split("-").reverse().join("/")} To {info?.end?.split("-").reverse().join("/")}
                            </Typography>
                        </Grid>
                    </Grid>
                    {(dataReceived != null) && <MachineBillingResult data={dataReceived} uniqId={uniqId} />}
                    {(isProcessingData) && <LoadingScreen />}
                </Grid>
            </Grid >
        </React.Fragment>)
}
export default MachineBillingShared;