import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Machine } from "../../SubPages/DataCenterDetails";
import axios from "axios";
import { API_PATH } from "../../../App";
import { useState } from "react";

function MachineDelete(props: { data: Machine, onClose: () => void, afterDelete: () => void, value?: string | null }) {
    const [isLoading, setIsLoading] = useState(false)
    function startDeleteMachine() {
        setIsLoading(true)
        axios.delete('/' + API_PATH + '/datacenter/' + props.data.centerId + "/machine", { data: [props.data] })
            .then(function (response) {
                props.afterDelete()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return <Dialog open={true} onClose={ props.onClose }>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Confirm Delete Machine - #{props.data.id} {props.data.workerId}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} disabled={isLoading}>Cancel</Button>
            <Button onClick={startDeleteMachine} color="error" disabled={isLoading}>Delete</Button>
        </DialogActions>
    </Dialog>
}
export default MachineDelete;