import React, { useEffect, useState } from "react";
import { Machine } from "../SubPages/DataCenterDetails";
import { Button, TextField, InputAdornment, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../App";

function MachineEnableDisable(props: { onChange: (data: Machine) => void, onCancel: () => void, data: Machine }) {
  const { onChange, onCancel, data } = props
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorText, setErrorText] = useState("");

  function startEnableMachine() {
    var x: Machine = JSON.parse(JSON.stringify(data));
    x.disable = !data.disable
    if (message.trim() == "") {
      setErrorText("Message can't be empty")
      return;
    }
    setIsLoading(true)
    x.message = message
    axios.post('/' + API_PATH + '/machine', x)
      .then(function (response) {
        onChange(x)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false)
      });
  }

  useEffect(() => {
  }, [])
  return (<Dialog open={true} onClose={onCancel} maxWidth="sm" fullWidth >
    <DialogTitle style={{ textAlign: "center" }}> Confirm {(data.disable) ? "Enable " : "Disable"}  Machine <br /> </DialogTitle>
    <DialogContent>
      <span>#{data.id} {data.workerId}  <br /> </span>
      <TextField
        label="Message"
        multiline
        rows={4}
        defaultValue={message}
        variant="filled"
        fullWidth
        onChange={(e) => { setMessage(e.target.value); if (errorText != "") { setErrorText("") } }}
      />
      {(errorText.trim().length > 0) && (<Alert severity="error">{errorText}</Alert>)}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={isLoading}>Cancel</Button>
      <Button onClick={startEnableMachine} disabled={isLoading} color="secondary">{(data.disable) ? "Enable " : "Disable"} </Button>
    </DialogActions>
  </Dialog>);
}

export default MachineEnableDisable;