import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import { API_PATH } from "../../App";
import { Machine, MachineConfig } from "../SubPages/DataCenterDetails";
import { FBuser } from "../../Helpers/Auth";

function MachineEdit(props: { data: Machine, machineConfigs: MachineConfig[], processCancelled: () => void, updated: (d: any) => void }) {
    const { processCancelled, machineConfigs, updated, data } = props

    const [model, setModel] = React.useState<string>(data.model);
    const [url, setUrl] = React.useState<string>(data.url);
    const [disable, setDisable] = React.useState<boolean>(data.disable);
    const [workerId, setWorkerId] = React.useState<string>(data.workerId);
    const [configId, setConfigId] = React.useState<string>(data.configId);
    const [serial, setSerial] = React.useState<string>(data.serialNo);


    const [hasError, setError] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState(false);

    function processSave() {
        if (workerId == "") {
            setError("WorkerId can't be empty")
            return;
        }
        if (configId == "") {
            setError("Config is not selected")
            return;
        }
        setIsLoading(true)
        data.model = model
        data.workerId = workerId
        data.url = url
        data.disable = disable
        data.configId = configId
        data.snoozeInMinutes = 0
        data.serialNo = serial
        if (data.id == "") {
            axios.put('/' + API_PATH + '/datacenter/' + data.centerId + "/machine",
                [data])
                .then(function (response) {
                    updated(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            axios.post('/' + API_PATH + "/machine",
                data)
                .then(function (response) {
                    updated(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    return (
        <Dialog open={true} onClose={processCancelled}>
            <DialogTitle>{(data.id == "") ? "Add Machine" : "Edit Machine"}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="WorkerId"
                    type="text"
                    fullWidth
                    value={workerId}
                    onChange={(e) => { setWorkerId(e.target.value) }}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Model"
                    type="text"
                    fullWidth
                    value={model ?? ""}
                    onChange={(e) => { setModel(e.target.value) }}
                    variant="standard"
                    disabled={(data.id == "") ? false : true}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Serial Number"
                    type="text"
                    fullWidth
                    value={serial ?? ""}
                    onChange={(e) => { setSerial(e.target.value) }}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Url"
                    type="text"
                    fullWidth
                    value={url ?? ""}
                    onChange={(e) => { setUrl(e.target.value) }}
                    variant="standard"
                />
                <FormControl variant="filled" fullWidth>
                    <InputLabel id="config-label">Config</InputLabel>
                    <Select
                        labelId="config-label"
                        value={configId}
                        onChange={(e => { setConfigId(e.target.value) })}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {machineConfigs.map((n, i) => {
                            return <MenuItem key={i} value={n.id}>{n.model}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {/* <Grid container style={{ marginTop: "5px" }}>
                    <FormLabel id="disable-group-label" style={{ marginTop: "7px", marginRight: "20px" }} >Disabled</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="disable-group-label"
                        name="disable-group"
                        value={disable}
                        onChange={(e) => { setDisable(e.target.value as unknown as boolean) }}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="True" />
                        <FormControlLabel value={false} control={<Radio />} label="False" />
                    </RadioGroup>
                </Grid> */}
                {(hasError != '') && (<Alert style={{ marginTop: "5px" }} severity="error">{hasError}</Alert>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={processCancelled} disabled={isLoading}>Cancel</Button>
                <Button onClick={processSave} disabled={isLoading}>Save</Button>
            </DialogActions>
        </Dialog>)
}

export default MachineEdit;