import { useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { API_PATH } from "../../../App";


function MachineUpdateHostingCharge(props: { macIds?: string[], onClose?: () => void, afterUpdate?: () => void, value?: string | null }) {
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [price, setPrice] = useState((props.value) ? props.value : "")
    function onSubmit() {
        if (price.length == 0) {
            setErrorMessage("Price can't be empty!")
            return;
        }
        if (props.macIds == null || props.macIds.length == 0) {
            setErrorMessage("Need to select atleast one machine!")
            return;
        }
        setIsLoading(true);
        axios.post('/' + API_PATH + "/machine/update_pricing",
            {
                "macIds": props.macIds,
                "price": price
            })
            .then((response) => {
                setIsLoading(false);
                (props.afterUpdate) && props.afterUpdate()
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorMessage("Error saving data!")
            });
    }
    if (props.macIds == null || props.macIds.length == 0) {
        return <></>
    }
    return <>
        <Snackbar
            open={errorMessage.length > 0}
            autoHideDuration={2000}
            onClose={() => { setErrorMessage("") }}
            message={errorMessage}
            color="error"
        />
        <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="md" >
            <DialogTitle>Update Hosting charge</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <TextField label="Hosting charge / Hour" variant="outlined" fullWidth value={price ?? ""} onChange={(e) => { setPrice(e.target.value) }} style={{ width: "100%", marginTop: "10px" }} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} disabled={isLoading}>Cancel</Button>
                <Button onClick={onSubmit} disabled={isLoading}>Save</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default MachineUpdateHostingCharge;