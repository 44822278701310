import { Grid } from "@mui/material";
import { useEffect } from "react";
import TileMachinePool from "./TileMachinePool";
import TileUserGroup from "./TileUserGroup";
import TileMachineOnlineStatus from "./TileMachineOnlineStatus";
import TileMachineBillingStatus from "./TileMachineBillingStatus";

function SettingsPage() {

  useEffect(() => { }, [])

  return (<Grid container >
    <Grid container style={{ padding: "10px" }}>
      <TileMachinePool />
      <TileUserGroup />
      <TileMachineOnlineStatus />
      <TileMachineBillingStatus />
    </Grid>
  </Grid>)
}

export default SettingsPage;