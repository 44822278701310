import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import { poolUrlInfo, validatePoolURL } from "../../../../Helpers/HelperFunctions";
import axios from "axios";
import { API_PATH } from "../../../../App";

function SettingsPoolAdd(props: { onSave: () => void, onClose: () => void }) {
    const { onSave, onClose } = props
    const [isProcessingData, setIsProcessingData] = useState<boolean>(false);
    const [isUrlProcessed, setIsUrlProcessed] = useState<boolean>(false);
    const [isCheckPassed, setIsCheckPassed] = useState<boolean | null>(null);
    const [poolUrl, setPoolUrl] = useState("");
    const [currency, setCurrency] = useState("");
    const [poolUser, setPoolUser] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    function processUrl() {
        setIsUrlProcessed(true)
        setIsCheckPassed(null)
        setErrorMessage("")
        if (validatePoolURL(poolUrl)) {
            var res = poolUrlInfo(poolUrl)
            setCurrency(res.currency);
            setPoolUser(res.username);
            if (res.username != undefined && res.currency != undefined) {
                setIsCheckPassed(true)
            } else {
                setIsCheckPassed(false)
                setErrorMessage("Invalid URL")
            }
        } else {
            setIsCheckPassed(false)
        }
    }
    function savePoolUrl() {
        processUrl()
        if (isCheckPassed) {
            setIsProcessingData(true)
            axios.put('/' + API_PATH + '/setting/f2pool',
                { "poolUrl": poolUrl })
                .then(function (response) {
                    setIsProcessingData(false)
                    if (response.data.success) {
                        onSave()
                    } else {
                        setErrorMessage(response.data.error)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    return <Dialog
        open={true}
        onClose={onClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                savePoolUrl()
            },
        }}
        fullWidth={true}
        maxWidth="md" >
        <DialogTitle>Add Pool URL</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={12} style={{ marginTop: "10px" }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="pool-url">Pool URL</InputLabel>
                        <OutlinedInput
                            id="pool-url"
                            type={'text'}
                            error={!(isCheckPassed ?? true)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Validate"
                                        onClick={e => { processUrl() }}
                                        onMouseDown={e => { setIsUrlProcessed(false) }}
                                        edge="end"
                                    >
                                        {isUrlProcessed ? (isCheckPassed == null ? <RefreshIcon /> : isCheckPassed ? <DoneIcon /> : <ClearIcon />) : <SearchIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Pool URL"
                            fullWidth
                            value={poolUrl}
                            onChange={e => { setPoolUrl(e.target.value); setIsUrlProcessed(false) }}
                            onKeyUp={e => { if (e.key === 'Enter' || e.keyCode === 13) { processUrl() } }}
                        />
                    </FormControl>
                </Grid>
                {(isCheckPassed) && (<Grid item xs={12} style={{ marginTop: "10px" }}> CURRENCY : {currency} , USER : {poolUser}  </Grid>)}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isProcessingData}>Cancel</Button>
            <Button type="submit" disabled={isProcessingData}>Add</Button>
        </DialogActions>
        {(errorMessage != "") && (<Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => { setErrorMessage("") }}
            message={errorMessage}
        />)}
    </Dialog>
}
export default SettingsPoolAdd;