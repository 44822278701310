import { Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, Alert, DialogActions, Button } from "@mui/material";
import { UserGroup } from "./settings/UserGroup/SettingsUserGroup";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../../App";



function AddToUserGroupModal(props: { show: boolean, isloading: boolean, defaultVal?: number | string | null, onSubmit: (selected: UserGroup | null) => void, onClose: () => void }) {

    const [isLoading, setIsLoading] = useState(props.isloading);
    const [rows, setRows] = useState(new Map<number, UserGroup>());
    const [selected, setSelected] = useState<string>((props.defaultVal) ? props.defaultVal.toString() : "");

    function listAll() {
        setIsLoading(true);
        axios.get('/' + API_PATH + '/usergroup')
            .then(function (response) {
                var res = new Map<number, UserGroup>()
                response.data.forEach((n: any) => {
                    res.set(n.id, n)
                });
                setRows(res);
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    useEffect(() => {
        listAll()
    }, [])

    return <Dialog open={props.show} onClose={props.onClose} fullWidth maxWidth="md" >
        <DialogTitle>Add to User Group</DialogTitle>
        <DialogContent>
            <FormControl variant="filled" fullWidth>
                <InputLabel id="config-ug">Select User Group</InputLabel>
                <Select
                    labelId="config-ug"
                    defaultValue={(rows.size == 0) ? "" : (props.defaultVal) ? props.defaultVal.toString() : ""}
                    value={(rows.size == 0) ? "" : selected}
                    onChange={(e => { setSelected(e.target.value) })}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {Array.from(rows, ([key, value]) => (value)).map((n, i) => {
                        return <MenuItem key={i} value={n.id}>{n.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} disabled={isLoading || props.isloading}>Cancel</Button>
            <Button onClick={e => { props.onSubmit(selected == "" ? null : rows.get(Number(selected))!) }} disabled={isLoading || props.isloading}>Save</Button>
        </DialogActions>
    </Dialog>


}
export default AddToUserGroupModal;