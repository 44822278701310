import { Chip, Grid, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useEffect } from "react";
import { DataCenter, DataCenterInfo } from "../SubPages/DataCenters";

function DatacenterInfoElement(props: { data: DataCenterInfo | null, datacenter: DataCenter }) {

  const { data, datacenter } = props
  useEffect(() => {
  }, [])
  if (data == null) {
    return <></>
  }
  return (<>
    <div>
      <Chip className="tinyTxt" color={(datacenter.total_machines == null) ? "error" : "primary"} variant="outlined" label={"Total : " + datacenter.total_machines} size="small" />
      <Chip className="tinyTxt" style={{ borderStyle: "dotted" }} variant="outlined" label={"Scaned : " + data.total} size="small" />
      {(data.hasError > 0) && (<Chip className="tinyTxt" color="error" label={"Error : " + data.hasError} size="small" />)}
      {(data.snoozed > 0) && (<Chip className="tinyTxt" color="warning" label={"Snoozed : " + data.snoozed} size="small" variant="outlined" />)}
      {(data.disabled > 0) && (<Chip className="tinyTxt" label={"Disabled : " + data.disabled} size="small" variant="outlined" />)}
      {(data.hasWarning > 0) && (<Chip className="tinyTxt" color="warning" label={"Warning : " + data.hasWarning} size="small" />)}
      {(data.repairRequired > 0) && (<Chip className="tinyTxt" label={"Repair Needed : " + data.repairRequired} size="small" variant="outlined" />)}
      {(data.onRepair > 0) && (<Chip className="tinyTxt" label={"On Repair : " + data.onRepair} size="small" variant="outlined" />)}
    </div>
    {hasErrors(data, datacenter)}
  </>
  )
}

function hasErrors(data: DataCenterInfo | null, datacenter: DataCenter) {
  if (datacenter.total_machines == null) {
    return <div>
      <Chip style={{ borderStyle: "dotted" }} className="tinyTxt" color="error" variant="outlined" label={String.fromCharCode(9888) + "Total Machine is not updated yet"} size="small" />
    </div>
  }
  if (data == null) {
    return <></>
  }
  return (
    <div>
      {(Number(datacenter.total_machines) > data.total) && (<Chip style={{ borderStyle: "dotted" }} className="tinyTxt" variant="outlined" color="error" label={String.fromCharCode(9888) + " Missing : " + (Number(datacenter.total_machines) - data.total)} size="small" />)}
      {(Number(datacenter.total_machines) < data.total) && (<Chip style={{ borderStyle: "dotted" }} className="tinyTxt" variant="outlined" color="error" label={String.fromCharCode(9888) + " Miss Match : " + (data.total - Number(datacenter.total_machines))} size="small" />)}
    </div>)
}
export default DatacenterInfoElement;