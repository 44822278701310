import React, { useEffect, useState } from "react";
import { Machine } from "../SubPages/DataCenterDetails";
import { Button, TextField, InputAdornment, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import axios from "axios";
import { API_PATH } from "../../App";

function MachineUpdateHashRate(props: { onChange: (data: Machine) => void, onCancel: () => void, data: Machine }) {
  const { onChange, onCancel, data } = props
  const [hashRate, setHashRate] = React.useState(data.hashRate ?? "");
  const [reason, setReason] = React.useState("");
  const [alertElm, setAlertElm] = React.useState<any | null>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  function updteMachine() {
    var rate = Number(hashRate)
    if (rate == null) {
      setAlertElm(<Alert severity="error">Invalid Hash Rate</Alert>)
      return
    }
    if (reason.trim() == "") {
      setAlertElm(<Alert severity="error">Message can't be empty</Alert>)
      return
    }
    setIsProcessing(true)
    setAlertElm(null)
    var x = JSON.parse(JSON.stringify(data));;
    // x.snoozeTill = data.snoozeTill
    x.hashRate = rate;
    x.message = reason;
    setIsProcessing(true)
    axios.post('/' + API_PATH + "/machine/hash_rate", x)
      .then(function (response) {
        setIsProcessing(false)
        onChange(x)
      })
      .catch(function (error) {
        setIsProcessing(false)
        console.log(error);
      });
  }

  useEffect(() => {

  }, [])
  return (<Dialog open={true} onClose={() => { onCancel() }}>

    <DialogTitle style={{ textAlign: "center" }}> Update Hash Rate for <br /> #{data.id} {data.workerId}</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label=""
        fullWidth
        value={hashRate}
        variant="standard"
        InputProps={{
          startAdornment: <InputAdornment position="start">Set Hash Rate </InputAdornment>,
          endAdornment: <InputAdornment position="end"></InputAdornment>
        }}
        onChange={(e) => { setHashRate(e.target.value) }}
      />
      <TextField
        label="Message"
        multiline
        rows={4}
        fullWidth
        defaultValue={reason}
        style={{ marginTop: "5px" }}
        onChange={(e) => { setReason(e.target.value) }}
      />
    </DialogContent>

    <DialogActions>
      <Grid container>
        <Grid item xs={12}>
          {(alertElm != null) && (alertElm)}
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={isProcessing} onClick={() => { onCancel() }}>Cancel</Button>
          <Button disabled={isProcessing} onClick={() => { updteMachine(); }} color="secondary"> Update </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>);
}

export default MachineUpdateHashRate;