import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SettingsPoolAdd from "./SettingsPoolAdd";
import axios from "axios";
import { API_PATH } from "../../../../App";
import LoadingScreen from "../../LoadingScreen";
import DeleteIcon from '@mui/icons-material/Delete';

export interface F2Pool {
  id: string;
  url: string;
  user: string;
}

export function createF2Pool(n: any
): F2Pool {
  return n;
}
function SettingsPool(props: { onclose: () => void }) {
  const { onclose } = props
  const [newPoolUrl, setNewPoolUrl] = useState<boolean>(false);
  const [isProcessingData, setIsProcessingData] = useState<boolean>(false);

  const [rows, setRows] = useState<F2Pool[]>([])
  const [deleteF2Pool, setDeleteF2Pool] = useState<F2Pool | null>(null);

  function listAllF2Pools() {
    setIsProcessingData(true);

    axios.get('/' + API_PATH + '/setting/f2pool')
      .then(function (response) {
        var res: F2Pool[] = []
        response.data.forEach((n: any) => {
          res.push(createF2Pool(n))
        });
        setRows(res);
        setIsProcessingData(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  function deletePool(d: F2Pool) {

  }
  useEffect(() => {
    listAllF2Pools()
  }, [])

  return (<Grid container style={{ padding: "10px" }} >

    <Grid item xs={12}>
      <Grid container><div style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }} onClick={e => { onclose() }} ><CloseIcon /></div></Grid>
      <Grid container >
        <Grid item xs={9}>
          <Typography variant="h6" component="h2"> Pools </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={() => { setNewPoolUrl(true) }} >Add</Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <TableContainer component={Paper} style={{ marginTop: "10px" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>User</TableCell>
              <TableCell> - </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >{row.id}</TableCell>
                <TableCell component="th" scope="row" style={{ wordBreak: "break-all" }}>
                  {row.url}
                </TableCell>
                <TableCell >{row.user}</TableCell>
                <TableCell align="right"> <a onClick={e => { setDeleteF2Pool(row) }}><DeleteIcon /></a> </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    {(newPoolUrl) && (<SettingsPoolAdd onSave={() => { setNewPoolUrl(false); listAllF2Pools(); }} onClose={() => { setNewPoolUrl(false) }} />)}
    {(isProcessingData) && (<div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "200px", background: "rgba(255,255,255,0.8)", zIndex: 1300 }}><Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">  <CircularProgress /> </Box></div>)}
  </Grid>)
}

export default SettingsPool;