import {useState, useEffect} from "react";
import axios from "axios";
import {API_PATH} from "../../../App";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, TextField} from "@mui/material";
import {MachinePoolConfigDto} from "../../../Helpers/Models";
import {Machine} from "../../SubPages/DataCenterDetails";

function MachineUpdatePool(props: { data: Machine, onClose: () => void, afterUpdate?: () => void }) {
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [poolConfigs, setPoolConfigs] = useState<MachinePoolConfigDto[]>([]);

    useEffect(() => {
        axios.get(`/segments/machine/${props.data.id}/poolConfig`)
            .then((response) => {
                setPoolConfigs(response.data);
            })
            .catch((error) => {
                setErrorMessage("Error fetching data!");
            });
    }, [props.data.id]);

    function onSubmit() {
        setIsLoading(true);
        axios.post(`/segments/machine/${props.data.id}/poolConfig`, {
           "configs": poolConfigs
        })
            .then((response) => {
                setIsLoading(false);
                if (response.data.status) {

                    alert(response.data.message);
                } else {

                    alert("Error: " + response.data.message);
                }
                (props.afterUpdate) && props.afterUpdate();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorMessage("Error saving data!");
            });
    }

    return (
        <>
            <Snackbar
                open={errorMessage.length > 0}
                autoHideDuration={2000}
                onClose={() => { setErrorMessage("") }}
                message={errorMessage}
                color="error"
            />
            <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="md">
                <DialogTitle>Change Pool Config:</DialogTitle>
                <DialogContent>
                    {poolConfigs.map((config, index) => (
                        <Grid key={index} container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Priority"
                                    variant="outlined"
                                    fullWidth
                                    value={config.priority}
                                    onChange={(e) => {
                                        const updatedConfigs = [...poolConfigs];
                                        updatedConfigs[index].priority = parseInt(e.target.value) || 0;
                                        setPoolConfigs(updatedConfigs);
                                    }}
                                    style={{ marginTop: "10px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Pool URL"
                                    variant="outlined"
                                    fullWidth
                                    value={config.poolUrl}
                                    onChange={(e) => {
                                        const updatedConfigs = [...poolConfigs];
                                        updatedConfigs[index].poolUrl = e.target.value;
                                        setPoolConfigs(updatedConfigs);
                                    }}
                                    style={{ marginTop: "10px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Worker ID"
                                    variant="outlined"
                                    fullWidth
                                    value={config.workerId}
                                    onChange={(e) => {
                                        const updatedConfigs = [...poolConfigs];
                                        updatedConfigs[index].workerId = e.target.value;
                                        setPoolConfigs(updatedConfigs);
                                    }}
                                    style={{ marginTop: "10px" }}
                                />
                            </Grid>
                        </Grid>

                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} disabled={isLoading}>Cancel</Button>
                    <Button onClick={onSubmit} disabled={isLoading}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MachineUpdatePool;


