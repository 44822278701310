import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_PATH } from "../../App";
import { DataCenter, createDataCenter } from "./DataCenters";
import LoadingScreen from "../other/LoadingScreen";
import { Grid, Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export interface Engineer {
    eid: string;
    name: string;
    email: string;
    phone: string;
    whatsapp: string;
    botim_id: string;
    dataCenter: string | null;
}

export function createEngineerFromObject(data: any): Engineer {
    return createEngineer(data.eid, data.name, data.email, data.phone, data.whatsapp, data.botim_id, null)
}
export function createEngineer(
    eid: string,
    name: string,
    email: string,
    phone: string,
    whatsapp: string,
    botim_id: string,
    dataCenter: string | null
): Engineer {
    return { eid, name, email, phone, whatsapp, botim_id, dataCenter };
}

function Engineers() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDatacenterLoaded, setIsDatacenterLoaded] = useState(false);
    const [isEngineersLoaded, setIsEngineersLoaded] = useState(false);
    const [engineerToEdit, setIsEngineerToEdit] = useState<Engineer | null>(null);
    const [engineerToDelete, setIsEngineerToDelete] = useState<Engineer | null>(null);
    const [datacenters, setDatacenters] = useState(new Map<string, DataCenter>());
    const [datacenterEngineer, setDatacenterEngineer] = useState(new Map<string, string>());
    const [engineerData, setEngineerData] = useState<Engineer[]>([]);
    function getDataCenters() {
        setIsDatacenterLoaded(false)
        axios.get(API_PATH + '/datacenter')
            .then(function (response) {
                // handle success
                var result = new Map<string, DataCenter>()
                var dcEngineer = new Map<string, string>()
                response.data.forEach((n: any) => {
                    if (n.tid != undefined || n.tid != null) {
                        dcEngineer.set(n.tid, n.id)
                    }
                    result.set(n.id, createDataCenter(n.id, n.centername, n.disable, null, n.total_machines, n.engineerid, n.bot_key, n.chat_id, n.restart, n.zen_key))
                })
                setDatacenters(result)
                setDatacenterEngineer(dcEngineer)
                setIsDatacenterLoaded(true)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setIsDatacenterLoaded(true)
            })
    }
    function getEngineers() {
        setIsEngineersLoaded(false)
        axios.get(API_PATH + '/engineers')
            .then(function (response) {
                var result: Engineer[] = []
                response.data.forEach((n: any) => {
                    result.push(createEngineer(n.eid, n.name, n.email, n.phone, n.whatsapp, n.botim_id, null))
                })
                setEngineerData(result)
                setIsEngineersLoaded(true)
            })
            .catch(function (error) {
                console.log(error);
                setIsEngineersLoaded(true)
            })
    }
    function handleClose() {
        setIsEngineerToEdit(null);
    }
    function processData(d: Engineer) {
        setIsSaving(true);
        if (d.eid == "") {
            axios.post(API_PATH + '/engineers', JSON.parse(JSON.stringify(d)))
                .then(function (response) {
                    handleClose()
                    setIsSaving(false);
                    getEngineers()
                })
                .catch(function (error) {
                    console.log(error);
                    setIsSaving(false);
                });
        } else {
            axios.put(API_PATH + '/engineers', JSON.parse(JSON.stringify(d)))
                .then(function (response) {
                    handleClose()
                    setIsSaving(false);
                    getEngineers()
                })
                .catch(function (error) {
                    console.log(error);
                    setIsSaving(false);
                });
        }
    }
    function deleteEngineer(d: Engineer) {
        setIsSaving(true);
        axios.delete(API_PATH + '/engineers', { data: JSON.parse(JSON.stringify(d)) })
            .then(function (response) {
                setIsSaving(false);
                getEngineers()
                setIsEngineerToDelete(null)
            })
            .catch(function (error) {
                console.log(error);
                setIsSaving(false);
            });
    }
    useEffect(() => {
        getDataCenters()
        getEngineers()
    }, [])

    return <Grid container >

        <Dialog
            open={engineerToDelete != null}
            onClose={() => { setIsEngineerToDelete(null) }}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    deleteEngineer(engineerToDelete!)
                },
            }}
            fullWidth={true}
            maxWidth="sm" >
            <DialogTitle>Delete Engineer</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Confirm delete user {engineerToDelete?.eid} {engineerToDelete?.name}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setIsEngineerToDelete(null) }} disabled={isSaving}>Cancel</Button>
                <Button type="submit" disabled={isSaving}>Delete</Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={engineerToEdit != null}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const n = Object.fromEntries((formData as any).entries());
                    processData(createEngineer(n.tid, n.name, n.email, n.phone, n.whatsapp, n.botim_id, null))
                },
            }}
        >
            <DialogTitle>{(engineerToEdit?.eid == "") ? "Add new Engineer" : "Edit : #" + engineerToEdit?.eid + " " + engineerToEdit?.name}</DialogTitle>
            <DialogContent>
                <TextField
                    name="tid"
                    label="tid"
                    type="text"
                    defaultValue={engineerToEdit?.eid}
                    style={{ display: "none" }}
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    defaultValue={engineerToEdit?.name}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    defaultValue={engineerToEdit?.email}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    name="phone"
                    label="Phone"
                    type="text"
                    fullWidth
                    defaultValue={engineerToEdit?.phone}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    name="whatsapp"
                    label="WhatsApp"
                    type="text"
                    fullWidth
                    defaultValue={engineerToEdit?.whatsapp}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    name="botim_id"
                    label="Botim"
                    type="text"
                    fullWidth
                    defaultValue={engineerToEdit?.botim_id}
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} disabled={isSaving}>Cancel</Button>
                <Button type="submit" disabled={isSaving}>Save</Button>
            </DialogActions>
        </Dialog>

        <Grid container style={{ padding: "20px" }} >
            <Grid item xs={9}>
                <Typography variant="h4" component="h2">
                    Engineers
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Button variant="contained" style={{ float: "right" }} endIcon={<AddIcon style={{ margin: "0" }} />} onClick={() => { setIsEngineerToEdit(createEngineer("", "", "", "", "", "", null)) }} >Add</Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>
                {(engineerData?.length == 0) && (<Grid item xs={12} style={{ textAlign: "center", padding: "40px 0px" }}><span>None</span></Grid>)}
                {(engineerData?.length > 0) && (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow key={-1} >
                                    <TableCell>Name</TableCell>
                                    <TableCell>email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>#</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {engineerData.map((row: Engineer) => (
                                    <TableRow
                                        key={row.eid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row"> # {row.eid}  {row.name} </TableCell>
                                        <TableCell >{row.email}</TableCell>
                                        <TableCell >{row.phone}</TableCell>
                                        {/* <TableCell align="right">{(datacenterEngineer.get(row.eid) == undefined) ? "None" : datacenters.get(datacenterEngineer.get(row.eid) ?? "")?.centername}</TableCell> */}
                                        <TableCell align="right">
                                            <a onClick={() => { setIsEngineerToDelete(row) }}>
                                                <DeleteIcon fontSize="small" style={{ cursor: "pointer" }} />
                                            </a>
                                            &nbsp;  &nbsp;
                                            <a onClick={() => { setIsEngineerToEdit(row) }}>
                                                <EditIcon fontSize="small" style={{ cursor: "pointer" }} />
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
        {(isLoading || !isDatacenterLoaded || !isEngineersLoaded) && (<LoadingScreen />)}
    </Grid>
}

export default Engineers
