
import axios from "axios";

import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Select, MenuItem, Alert, DialogActions, Button } from "@mui/material";
import { UserGroup } from "../settings/UserGroup/SettingsUserGroup";
import React, { useEffect } from "react";
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { API_PATH } from "../../../App";
import { AuthUser } from "../../SubPages/Users";


function AddUser(props: { show: boolean, userGroups: UserGroup[], onComplete: () => void, user: AuthUser | null }) {
    const { show: showAddUser, userGroups, onComplete, user } = props

    const [userName, setUserName] = React.useState(user?.authName ?? "");
    const [userType, setUserType] = React.useState(user?.authRole ?? "CLIENT");
    const [userGroupSelected, setUserGroupsSelected] = React.useState(user?.userGroup?.id ?? "");
    const [userEmail, setUserEmail] = React.useState(user?.authEmail ?? "");
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [userPwd1, setUserPwd1] = React.useState("");
    const [userPwd2, setUserPwd2] = React.useState("");
    const [userErrorMsg, setUserErrorMsg] = React.useState("");
    const [isUserCreating, setIsUserCreatiing] = React.useState(false);

    function cleanFormData() {
        setUserName("")
        setUserEmail("")
        setUserType("CLIENT")
        setUserPwd1("")
        setUserPwd2("")
        setUserErrorMsg("")
        setUserGroupsSelected("")
        setShowPassword1(false)
        setShowPassword2(false)
    }
    useEffect(() => {
        if (user != null) {
            setUserName(user.authName ?? "")
            setUserEmail(user.authEmail)
            setUserType(user.authRole)
            setUserGroupsSelected(user.userGroup?.id ?? "")
        }
        console.log(user)
    }, [])
    function addNewUser() {
        if (!(/\S+@\S+\.\S+/.test(userEmail))) {
            setUserErrorMsg("Invalid Email")
            return;
        }
        if (user == null) {
            if (userPwd1 == "" || userPwd1 != userPwd2) {
                setUserErrorMsg("Invalid Password")
                return;
            }
            if (userType == "CLIENT" && (userGroupSelected == "")) {
                setUserErrorMsg("Select user group")
                return;
            }
        }
        setIsUserCreatiing(true)
        createUser(userName, userEmail, userPwd1, userType, (userType == "CLIENT" ? userGroupSelected : ""), (stat) => {
            setIsUserCreatiing(false)
            if (stat) {
                cleanFormData();
                onComplete();
            } else {
                setUserErrorMsg("Error Processing Data")
            }
        })
    }
    function createUser(name: string, email: string, pwd: string, type: string, ug: string, callback: (status: boolean) => void) {
        axios.post(API_PATH + ((user == null) ? '/auth/newUsers' : '/auth/editUsers'), { name: name, email: email, password: pwd, type: type, ug: ug, id: user?.authId ?? "" })
            .then(resp => {
                callback(true)
            })
            .catch(e => {
                callback(false)
            })
    }
    return (
        <>

            <Dialog open={showAddUser} onClose={() => { onComplete() }}>
                <DialogTitle>{(user == null) ? "ADD" : "UPDATE"} USER</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor="u-name">Name</InputLabel>
                                <OutlinedInput
                                    id="u-name"
                                    type="text"
                                    fullWidth
                                    value={userName}
                                    onChange={(e) => { setUserName(e.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label=" "
                                                edge="end"
                                            >
                                                <PersonIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Name"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor="u-role">Type</InputLabel>
                                <Select id="u-role" value={userType} label="Type"
                                    onChange={(e) => { setUserType(e.target.value) }}  >
                                    <MenuItem value={"CLIENT"}>Client</MenuItem>
                                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                    <MenuItem value={"DISABLED"}>Disabled</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {(userType == "CLIENT") && (<>
                            <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel htmlFor="u-group">User Group</InputLabel>
                                    <Select id="u-group" value={userGroupSelected} label="User Group"
                                        onChange={(e) => { setUserGroupsSelected(e.target.value) }}  >
                                        <MenuItem value={""}>NONE</MenuItem>
                                        {(userGroups.map((n, k) => <MenuItem key={k} value={n.id}>{n.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid></>)}
                        <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel htmlFor="u-email">Email</InputLabel>
                                <OutlinedInput
                                    id="u-email"
                                    type="email"
                                    disabled={(user != null)}
                                    fullWidth
                                    value={userEmail}
                                    onChange={(e) => { setUserEmail(e.target.value) }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={(e) => { e.preventDefault() }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                                edge="end"
                                            >
                                                <AlternateEmailIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Email"
                                />
                            </FormControl>
                        </Grid>
                        {(user == null) && (<>
                            <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel htmlFor="u-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="u-password"
                                        type={showPassword1 ? 'text' : 'password'}
                                        fullWidth
                                        value={userPwd1}
                                        onChange={(e) => { setUserPwd1(e.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword1(!showPassword1) }}
                                                    onMouseDown={(e) => { e.preventDefault() }}
                                                    edge="end"
                                                >
                                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px" }} >
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel htmlFor="u-password-2">Repeat Password</InputLabel>
                                    <OutlinedInput
                                        id="u-password-2"
                                        type={showPassword2 ? 'text' : 'password'}
                                        fullWidth
                                        value={userPwd2}
                                        onChange={(e) => { setUserPwd2(e.target.value) }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { setShowPassword2(!showPassword2) }}
                                                    onMouseDown={(e) => { e.preventDefault() }}
                                                    edge="end"
                                                >
                                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Repeat Password"
                                    />
                                </FormControl>
                            </Grid>
                        </>)}
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "20px" }}>
                        {(userErrorMsg != "") && (<Alert severity="error">{userErrorMsg}</Alert>)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container style={{ paddingTop: "5px" }}>
                        <Grid item xs={12} style={{ paddingTop: "10px", minWidth: "400px", textAlign: "right" }} >
                            <Button onClick={addNewUser} color="success" disabled={isUserCreating} >{(user == null) ? "ADD" : "UPDATE"}</Button>
                            <Button onClick={() => { onComplete(); cleanFormData(); }} disabled={isUserCreating} >Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>)
}

export default AddUser;